import { fetchProfile, saveDealerProfile } from "@/apis/dealer";
import { mutateDealerInfo } from "@/features/dealerSlice";
import {
    CategoriesType,
    ManufactureType,
    ProfileType,
} from "@/types/dealer-type";
import { capitalize } from "@/utils";
import { stringConversion } from "@/utils/profile";
import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";

export default function useProfileHook() {
    const dispatch = useDispatch();

    const [profileImage, setProfileImage] = useState(null);
    const [heroImage, setHeroImage] = useState(null);

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        message: "",
    });

    const [profile, setProfile] = useState<ProfileType>({
        name: "",
        email: "",
        address_one: "",
        url: "",
        city: "",
        password: "",
        mobile: "",
        profile_image: {
            id: null,
            name: "",
            path: "",
            title: "",
        },
        hero_image: {
            id: null,
            name: "",
            path: "",
            title: "",
        },
        categories: [],
        selectedCategories: [],
        selectedManufacturer: [],
        manufacturers: [],
        stateID: "",
        description: "",
        maintenance: "",
        parts: "",
        email_notification: false,
        sms_notification: false,
        terms_conditions: "",
    });

    const [dealerCategories, setDealerCategories] = useState<CategoriesType[]>(
        []
    );

    const [dealerManufacture, setDealerManufacture] = useState<
        ManufactureType[]
    >([]);

    const [hoursOperation, setHoursOperation] = useState([
        {
            id: 1,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 2,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 3,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 4,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 5,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 6,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
        {
            id: 7,
            day: "",
            opening_time: "",
            closing_time: "",
            holiday: false,
        },
    ]);

    const { isLoading, refetch } = useQuery("get-profile-info", fetchProfile, {
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            dispatch(mutateDealerInfo(data.dealer));

            setProfile((prev) => ({
                ...prev,
                name: data.dealer.name,
                email: data.dealer.email,
                address_one: data.dealer.location,
                url: data.dealer.url,
                city: data.dealer.city,
                mobile: data.dealer.mobile,
                profile_image: data.dealer.profile_image,
                hero_image: data.dealer.hero_image_object,
                stateID: data.dealer.id_state,
                categories: data.categories,
                parts: data.dealer.parts,
                selectedCategories: data.action.selectedCategories,
                selectedManufacturer: data.action.selectedManufacturer,
                manufacturers: data.manufacture.data,
                description: data.dealer.description,
                maintenance: data.dealer.maintenance,
                terms_conditions: data.dealer.tnc,
                email_notification: stringConversion(
                    data.dealer.email_notification
                ),
                sms_notification: stringConversion(
                    data.dealer.sms_notification
                ),
            }));

            setHoursOperation((prevState) => {
                const newState = [...prevState];
                newState.forEach((item, index) => {
                    if (data.action.hoursOfOperation.allDays[index]) {
                        const day =
                            data.action?.hoursOfOperation?.allDays[index];
                        item.day = capitalize(day);
                    }
                    if (data.action.hoursOfOperation.openingTime) {
                        const opening_time =
                            data.action.hoursOfOperation.openingTime[index];
                        item.opening_time = opening_time;
                    }
                    if (data.action.hoursOfOperation.closingTime) {
                        const closing_time =
                            data.action.hoursOfOperation.closingTime[index];
                        item.closing_time = closing_time;
                    }
                    if (data.action.hoursOfOperation.holidays) {
                        const holidays =
                            data.action.hoursOfOperation.holidays[index];
                        item.holiday = holidays;
                    }
                });
                return newState;
            });
        },
    });

    const handleDayChange = (e: SelectChangeEvent<string>, index: number) => {
        const { value } = e.target;
        setHoursOperation((prev) => {
            const newState = [...prev];
            newState[index].day = value;
            return newState;
        });
    };

    const handleOPTChange = (e: SelectChangeEvent<string>, index: number) => {
        const { value } = e.target;
        setHoursOperation((prev) => {
            const newState = [...prev];
            newState[index].opening_time = value;
            return newState;
        });
    };

    const handleOCTChange = (e: SelectChangeEvent<string>, index: number) => {
        const { value } = e.target;
        setHoursOperation((prev) => {
            const newState = [...prev];
            newState[index].closing_time = value;
            return newState;
        });
    };

    const handleHolidayChange = (
        e: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        setHoursOperation((prev) => {
            const newHoursOperation = [...prev];
            newHoursOperation[index].holiday = e.target.checked;
            return newHoursOperation;
        });
    };

    const handleProfileImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            setProfileImage(event.target.files[0]);
        }
    };

    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            setHeroImage(event.target.files[0]);
        }
    };

    const dealerProfileMutation = useMutation(
        (payload: any) => {
            return saveDealerProfile(payload);
        },
        {
            onSuccess: ({ data }) => {
                refetch();
                setAlertInfo({
                    open: true,
                    message: data.message,
                });
            },
            onError: () => {
                setAlertInfo({
                    open: true,
                    message: "Something went wrong, try again",
                });
            },
            onSettled: () => {
                setProfileImage(null);
                setHeroImage(null);

                setTimeout(() => {
                    setAlertInfo({
                        open: false,
                        message: "",
                    });
                }, 3000);
            },
        }
    );

    const saveProfileInfo = async () => {
        const formData: any = new FormData();

        formData.append("name", profile.name);
        formData.append("location", profile.address_one);
        formData.append("parts", profile.parts);
        formData.append("url", profile.url);
        formData.append("password", profile.password);
        formData.append("city", profile.city);
        formData.append("id_state", profile.stateID);
        formData.append("description", profile.description);
        formData.append("maintenance", profile.maintenance);
        formData.append("terms_conditions", profile.terms_conditions);
        formData.append("profile_image", profileImage);
        formData.append("hero_image", heroImage);
        formData.append("email_notification", profile.email_notification);
        formData.append("sms_notification", profile.sms_notification);
        formData.append(
            `selected_categories`,
            dealerCategories.map((category) => category.base_id).join(",")
        );
        formData.append(
            `selected_manufacturer`,
            dealerManufacture
                .map((manufacture) => manufacture.base_id)
                .join(",")
        );

        hoursOperation.forEach((day, index) => {
            formData.append(`hoursOperation[${index}][day]`, day.day);
            formData.append(
                `hoursOperation[${index}][opening_time]`,
                day.opening_time
            );
            formData.append(
                `hoursOperation[${index}][closing_time]`,
                day.closing_time
            );
            formData.append(`hoursOperation[${index}][holiday]`, day.holiday);
        });

        dealerProfileMutation.mutate(formData);
    };

    return {
        profile,
        setProfile,
        profileImage,
        heroImage,
        dealerCategories,
        setDealerCategories,
        dealerManufacture,
        setDealerManufacture,
        hoursOperation,
        handleDayChange,
        handleOPTChange,
        handleOCTChange,
        handleHolidayChange,
        handleProfileImage,
        handleHeroImage,
        saveProfileInfo,
        alertInfo,
        setAlertInfo,
        isLoading,
        dealerProfileMutation,
    };
}
