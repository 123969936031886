import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

type ListSkeletonProps = {
    total: number;
};

export default function ListSkeleton({ total }: ListSkeletonProps) {
    return (
        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            {Array.from({ length: total }).map((sktln, index) => (
                <Box sx={{ width: index === 3 ? 200 : 300 }} key={index}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                </Box>
            ))}
        </Box>
    );
}
