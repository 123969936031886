import api from "@/config/api";

export const saveDealerProfile = async (payload: any) => {
    const response = await api().post("dealer/profile/update", payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
};

export const fetchProfile = () => api().get("/dealer/profile");
