import axios from "axios";

const api = () => {
    const token = localStorage.getItem("dealer-auth-token") || "";

    const response = axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
        headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            // signature: import.meta.env.API_SIGNATURE_KEY,
        },
    });

    // Setting up a response interceptor for Axios.
    // Interceptors are functions that run on every request or response before they are handled by `then` or `catch`.
    response.interceptors.response.use(
        // Success handler: This function executes when a response is received from the server.
        (response) => {
            // Simply return the response unchanged.
            return response;
        },
        // Error handler: This function executes if there's an error response from the server.
        (error) => {
            // Check if the error response status is 401, indicating an unauthorized request.
            if (error.response.status === 401) {
                // If it's a 401 error, it means the user is unauthenticated.

                // Remove the authentication token from local storage.
                localStorage.removeItem("dealer-auth-token");

                // Redirect the user to the login page by changing the current page's URL.
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000);
            }

            // Return a rejected promise with the error to ensure that the error continues to propagate down the promise chain.
            // This allows other error handlers to potentially handle it.
            return Promise.reject(error);
        }
    );

    return response;
};

export default api;
