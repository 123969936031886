export const STORAGE_DOMAIN = import.meta.env.VITE_STORAGE_DOMAIN;

// helper to truncate text
export function subStrText(str: any, limit = 5) {
    return str && str.length > limit ? str.substr(0, limit) + "..." : str;
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function show_data(data: any) {
    return JSON.stringify(data);
}

export const formattedPrice = (price: number | string | null | undefined) => {
    // Remove '$', space, and commas from the input string
    const priceWithoutDollarAndSpace = (price || "")
        .toString()
        .replace(/[$\s,]/g, "");

    // Format the modified price as a number
    const formattedPrice = Number(priceWithoutDollarAndSpace).toLocaleString(
        "en-US",
        {
            style: "currency",
            currency: "USD",
        }
    );

    return formattedPrice;
};

// Create a helper function to remove HTML tags and extract text content
export function removeHTMLTags(html: string) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText;
}

export function sanitizeImageDomain(path: string) {
    let sanitizedPath: string = "";

    if (path?.includes("public")) {
        sanitizedPath = path.replace(/^public\//, "");
        return STORAGE_DOMAIN + "/" + sanitizedPath;
    }

    if (path?.includes("storage")) {
        sanitizedPath = path.replace(/^\/storage/, "");
        return STORAGE_DOMAIN + "/" + sanitizedPath;
    }

    return STORAGE_DOMAIN + "/" + path;
}

export function transformImageUrl(imageUrl: string) {
    // Check if the image URL contains 'not-found.png'
    if (imageUrl.includes("not-found.png")) {
        return imageUrl;
    }
    // Remove the 'storage' word from the image URL

    if (imageUrl.includes("storage")) {
        imageUrl = imageUrl.replace("/storage/", "");
    }

    // Add the domain prefix
    const prefix = "https://recyclingbalers.s3.amazonaws.com/";
    const transformedUrl = prefix + imageUrl;

    return transformedUrl;
}
