import {
    mutateBrochureResource,
    mutatePDFResource,
} from "@/features/equipmentSlice";
import { AddEquipmentType } from "@/store/types/store-types";
import { ResourcesType } from "@/types/create-equipment";
import { sanitizeImageDomain } from "@/utils";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Resources({ brochureFile, specSheet }: ResourcesType) {
    const dispatch = useDispatch();

    const state = useSelector(
        (state: AddEquipmentType) => state.equipmentAdd.resources
    );

    const handlePdfBrochure = (event: ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;

        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            dispatch(
                mutateBrochureResource({
                    file: file,
                })
            );
        }
    };

    const handlePdfSheet = (event: any) => {
        const fileInput = event.target;

        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            dispatch(
                mutatePDFResource({
                    file: file,
                })
            );
        }
    };

    return (
        <Box
            sx={{
                boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                p: 2,
                borderRadius: 2,
                backgroundColor: "white",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    gap: 5,
                }}
            >
                <FormControl
                    sx={{
                        marginTop: 3,
                        width: `100%`,
                        border: 1,
                        borderColor: "#cbd5e1",
                        py: 2,
                        borderRadius: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: { md: "block", lg: "flex" },
                            alignItems: { sm: "center" },
                            height: "auto",
                        }}
                    >
                        <Button
                            variant="text"
                            component="label"
                            sx={{
                                width: { xs: `85%`, lg: `30%` },
                                ml: 2,
                                py: 1,
                                mb: { xs: 1, lg: 0 },
                                backgroundColor: "#93ca5c",
                                color: "white",
                                ":hover": {
                                    backgroundColor: "#15803d",
                                },
                            }}
                            endIcon={<CloudUploadIcon />}
                        >
                            PDF BROCHURE
                            <input
                                type="file"
                                accept=".pdf"
                                hidden
                                onChange={handlePdfBrochure}
                            />
                        </Button>

                        {brochureFile && (
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ ml: 2, fontSize: 12 }}
                            >
                                <Link
                                    style={{ textDecoration: "none" }}
                                    target={"_blank"}
                                    to={sanitizeImageDomain(brochureFile.path)}
                                >
                                    View Brochure
                                </Link>
                            </Button>
                        )}

                        {state.pdfBrochure instanceof File && (
                            <Typography
                                component={"p"}
                                sx={{
                                    mt: 0,
                                    ml: 2,
                                    width: { xs: `85%`, lg: `50%` },
                                    p: 1,
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "#94a3b8",
                                }}
                            >
                                {state.pdfBrochure["name"]}
                            </Typography>
                        )}
                    </Box>
                </FormControl>

                <FormControl
                    sx={{
                        marginTop: 3,
                        width: `100%`,
                        border: 1,
                        borderColor: "#cbd5e1",
                        py: 2,
                        borderRadius: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: { md: "block", lg: "flex" },
                            alignItems: { sm: "center" },
                            height: "auto",
                        }}
                    >
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                width: { xs: `85%`, lg: `30%` },
                                ml: 2,
                                py: 1,
                                mb: { xs: 1, lg: 0 },
                                backgroundColor: "#93ca5c",
                                color: "white",
                                ":hover": {
                                    backgroundColor: "#15803d",
                                },
                            }}
                            endIcon={<CloudUploadIcon />}
                        >
                            PDF SPEC SHEET
                            <input
                                type="file"
                                accept=".pdf"
                                hidden
                                onChange={handlePdfSheet}
                            />
                        </Button>

                        {specSheet && (
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{ ml: 2, fontSize: 12 }}
                            >
                                <Link
                                    style={{ textDecoration: "none" }}
                                    target={"_blank"}
                                    to={sanitizeImageDomain(specSheet.path)}
                                >
                                    View Sheet
                                </Link>
                            </Button>
                        )}

                        {state.pdfSheet instanceof File && (
                            <Typography
                                component={"p"}
                                sx={{
                                    mt: 0,
                                    ml: 2,
                                    width: { xs: `85%`, lg: `50%` },
                                    p: 1,
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "#94a3b8",
                                }}
                            >
                                {state.pdfSheet["name"]}
                            </Typography>
                        )}
                    </Box>
                </FormControl>
            </Box>
        </Box>
    );
}
