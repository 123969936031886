import catalogSlice from "@/features/catalogSlice";
import dealerSlice from "@/features/dealerSlice";
import equipmentSlice from "@/features/equipmentSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const reducers = combineReducers({
    equipmentAdd: equipmentSlice,
    catalogSlice: catalogSlice,
    dealerSlice: dealerSlice,
});

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
