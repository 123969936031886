import api from "@/config/api";
import { unsetDealerToken } from "@/features/dealerSlice";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const TOKEN_KEY = "dealer-auth-token";

export default function useAuthUserHook() {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const isAuthenticated = localStorage.getItem(TOKEN_KEY) ? true : false;

    const authUserAvatar = JSON.parse(
        localStorage.getItem("dealerInfo") as string
    )?.avatar;

    const processLogout = useMutation(
        () => {
            return api().post("/dealer/logout");
        },
        {
            onSuccess: () => {
                dispatch(unsetDealerToken());
                window.location.href = "https://recyclingbalers.com/";
            },
            onError: (err: AxiosError) => {
                console.log(err);
            },
            retry: 1,
        }
    );

    return {
        isAuthenticated,
        authUserAvatar,
        processLogout,
    };
}
