import useAuthUserHook from "@/hook/useAuthUserHook";
import { DealerStateType } from "@/store/types/store-types";
import { sanitizeImageDomain } from "@/utils";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import BottomNavbar, { UserMenuBar } from "./BottomNavigation";

const logoStyle = {
    width: "120px",
    height: "25px",
    cursor: "pointer",
};

const settings = [
    {
        id: 1,
        name: "Profile",
        route: "/profile",
        target: false,
        icon: <AccountCircleOutlinedIcon sx={{ mt: 0.8 }} />,
    },
    {
        id: 2,
        name: "Billing",
        target: false,
        route: "/billing",
        icon: <MonetizationOnOutlinedIcon sx={{ mt: 0.8 }} />,
    },
    {
        id: 3,
        name: "Try IOS App",
        target: true,
        route: "https://apps.apple.com/ca/app/recyclingbalers-dealer-app/id1555761472",
        icon: <PhoneIphoneOutlinedIcon sx={{ mt: 0.8 }} />,
    },
    {
        id: 4,
        name: "Try Android App",
        target: true,
        route: "https://play.google.com/store/apps/details?id=io.recyclingbalers.dealer",
        icon: <PhoneAndroidOutlinedIcon sx={{ mt: 0.8 }} />,
    },
];

function AppAppBar() {
    /** custom hook to deal with auth user */
    const { authUserAvatar, processLogout, isAuthenticated } =
        useAuthUserHook();

    const location = useLocation();

    const state = useSelector((state: DealerStateType) => state.dealerSlice);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const [open, setOpen] = useState(false);

    const scrollToSection = (sectionId: string) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: "smooth" });
            window.scrollTo({
                top: targetScroll,
                behavior: "smooth",
            });
            setOpen(false);
        }
    };

    // Check if the URL contains "equipment" but not "sold"
    const isEquipmentPage =
        location.pathname.includes("equipment") &&
        !location.pathname.includes("sold") &&
        !location.pathname.includes("add");

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: "transparent",
                    mt: { md: 1, xs: 0 },
                    display: { xs: "", md: "block" },
                }}
            >
                <Container maxWidth="xl" sx={{ px: { xs: 0, md: 4 } }}>
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexShrink: 0,
                            borderRadius: { md: "999px", xs: "" },
                            backgroundColor: "#FFFFFF",
                            backdropFilter: "blur(24px)",
                            maxHeight: 40,
                            border: "1px solid",
                            borderColor: "divider",
                            boxShadow: `0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)`,
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: 0,
                            }}
                        >
                            <img
                                src={
                                    "https://recyclingbalers.s3.amazonaws.com/logo.png"
                                }
                                style={logoStyle}
                                alt="logo of recycling balers"
                            />
                            <Box
                                sx={{
                                    display: {
                                        xs: "none",
                                        md: "flex",
                                        gap: { xl: 4, md: 2, lg: 3 },
                                    },
                                }}
                            >
                                <MenuItem
                                    sx={{
                                        py: "6px",
                                        px: "12px",
                                        fontSize: { lg: 16, md: 13, xl: 18 },
                                        fontWeight: 650,
                                        color: location.pathname?.includes(
                                            "add"
                                        )
                                            ? "#93ca5c"
                                            : "#6b7280",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={"/equipment/add"}
                                    >
                                        Sell Used Equipment
                                    </NavLink>
                                </MenuItem>

                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />

                                <MenuItem
                                    sx={{
                                        py: "6px",
                                        px: "12px",
                                        fontSize: { lg: 16, md: 13, xl: 18 },
                                        fontWeight: 700,
                                        color: isEquipmentPage
                                            ? "#93ca5c"
                                            : "#6b7280",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={"/equipment"}
                                    >
                                        Used List
                                    </NavLink>
                                </MenuItem>

                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />

                                <MenuItem
                                    sx={{
                                        py: "6px",
                                        px: "12px",
                                        fontSize: { lg: 16, md: 13, xl: 18 },
                                        fontWeight: 700,
                                        color: location.pathname?.includes(
                                            "sold"
                                        )
                                            ? "#93ca5c"
                                            : "#6b7280",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={"/equipment/sold"}
                                    >
                                        Sold List
                                    </NavLink>
                                </MenuItem>

                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />

                                <MenuItem
                                    sx={{
                                        py: "6px",
                                        px: "12px",
                                        fontSize: { lg: 16, md: 13, xl: 18 },
                                        fontWeight: 700,
                                        color: location.pathname?.includes(
                                            "catalog"
                                        )
                                            ? "#93ca5c"
                                            : "#6b7280",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={"/catalog"}
                                    >
                                        Dealer Catalog
                                    </NavLink>
                                </MenuItem>

                                <Divider
                                    orientation="vertical"
                                    variant="middle"
                                    flexItem
                                />

                                <MenuItem
                                    sx={{
                                        py: "6px",
                                        px: "12px",
                                        fontSize: { lg: 16, md: 13, xl: 18 },
                                        fontWeight: 700,
                                        color: location.pathname?.includes(
                                            "leads"
                                        )
                                            ? "#93ca5c"
                                            : "#6b7280",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                        to={"/leads"}
                                    >
                                        Leads
                                    </NavLink>
                                </MenuItem>
                            </Box>

                            {isAuthenticated ? (
                                <>
                                    <Box sx={{ flexGrow: 0, ml: 3 }}>
                                        <Tooltip title="Open settings">
                                            <IconButton
                                                onClick={(e) =>
                                                    setAnchorElUser(
                                                        e.currentTarget
                                                    )
                                                }
                                                sx={{ p: 0 }}
                                            >
                                                <Avatar
                                                    alt={
                                                        "Avatar of " +
                                                        state.dealerInfo.name
                                                    }
                                                    src={sanitizeImageDomain(
                                                        authUserAvatar
                                                    )}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <UserMenuBar
                                            showDropdown={anchorElUser}
                                            handleClose={() =>
                                                setAnchorElUser(null)
                                            }
                                        />
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: { xs: "none", md: "flex" },
                                        gap: 0.5,
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="text"
                                        size="small"
                                        component="a"
                                        href="/material-ui/getting-started/templates/sign-in/"
                                        target="_blank"
                                    >
                                        Sign in
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        component="a"
                                        href="/material-ui/getting-started/templates/sign-up/"
                                        target="_blank"
                                    >
                                        Sign up
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Box
                sx={{
                    display: { md: "none", xs: "block" },
                    px: 2,
                }}
            >
                <BottomNavbar />
            </Box>
        </div>
    );
}

export default AppAppBar;
