import { fetchEquipments } from "@/apis/equipment";
import CardSkeleton from "@/components/shared/loaders/CardSkeleton";
import ShowSnackbar from "@/components/shared/modals/Snackbar";
import useEquipmentHook from "@/hook/useEquipmentHook";
import { EquipmentList } from "@/types/equipment-list";
import { STORAGE_DOMAIN } from "@/utils";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DraftsIcon from "@mui/icons-material/Drafts";
import EditIcon from "@mui/icons-material/Edit";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    CircularProgress,
    Divider,
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, NavLink } from "react-router-dom";

export function UsedEquipmentList() {
    // Custom Equipment Hook
    const {
        equipmentSoldMutation,
        alertInfo,
        equipmentId,
        soldStatus,
        setAlertInfo,
    } = useEquipmentHook();

    // API Data
    const { data, isLoading } = useQuery("all-equipments", fetchEquipments, {
        refetchOnWindowFocus: false,
    });

    const equipments: EquipmentList[] = data?.data.data;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const listItems = equipments?.map((equip, index) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
            <Card
                sx={{
                    maxWidth: "auto",
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    transition: "box-shadow 0.2s ease-in-out",
                    cursor: "pointer",
                    "&:hover": {
                        boxShadow: `0 4px 28px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22)`,
                    },
                }}
            >
                <CardHeader
                    action={
                        <>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={(event) =>
                                    setAnchorEl(event.currentTarget)
                                }
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                            >
                                <MenuItem>
                                    <NavLink
                                        style={{
                                            textDecoration: "none",
                                            color: "gray",
                                        }}
                                        target={"_blank"}
                                        to={equip?.fullURL}
                                    >
                                        View Details
                                    </NavLink>
                                </MenuItem>
                                <MenuItem disabled>Delete</MenuItem>
                            </Menu>
                        </>
                    }
                    title={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: 2,
                            }}
                        >
                            {equip.approved === 1 ? (
                                <Button
                                    startIcon={<CheckBoxIcon />}
                                    size="small"
                                    sx={{
                                        backgroundColor: "#93ca5c",

                                        color: "#f9fafb",
                                        ":hover": {
                                            backgroundColor: "#166534",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Approved
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<PendingIcon />}
                                    size="small"
                                    sx={{
                                        backgroundColor: "#f59e0b",
                                        color: "#f9fafb",
                                        ":hover": {
                                            backgroundColor: "#d97706",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Pending
                                </Button>
                            )}

                            {equip.draftStatus !== 0 && (
                                <Button
                                    startIcon={<DraftsIcon />}
                                    size="small"
                                    sx={{
                                        backgroundColor: "#be185d",
                                        color: "#f9fafb",
                                        ":hover": {
                                            backgroundColor: "#831843",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Draft
                                </Button>
                            )}
                        </Box>
                    }
                />

                {equip.coverImage?.includes("not-found") ? (
                    <CardMedia
                        sx={{ objectFit: "contain" }}
                        component="img"
                        height="194"
                        image={equip.coverImage}
                        alt={"Recycling Baler Image of " + equip.title}
                    />
                ) : (
                    <CardMedia
                        sx={{ objectFit: "cover" }}
                        component="img"
                        height="194"
                        image={STORAGE_DOMAIN + equip.coverImage}
                        alt={"Recycling Baler Image of " + equip.title}
                    />
                )}

                <CardContent>
                    <Typography sx={{ mt: 2, fontWeight: 600, fontSize: 18 }}>
                        {equip.title}
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography
                            sx={{
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <VisibilityOutlinedIcon /> {equip.views_count} View
                        </Typography>
                        <Typography
                            sx={{
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <LocalPhoneOutlinedIcon />
                            {equip.phone_clicks_count} Phone Clicks
                        </Typography>
                        <Typography
                            sx={{
                                mt: 2,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <BarChartOutlinedIcon /> {equip.leads_count} Leads
                        </Typography>
                    </Box>
                </CardContent>
                <Divider />

                <CardActions
                    disableSpacing
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button size="small" startIcon={<EditIcon />}>
                        <Link
                            style={{ textDecoration: "none", color: "gray" }}
                            to={"/equipment/edit/" + equip.id}
                        >
                            Edit
                        </Link>
                    </Button>
                    {equipmentSoldMutation.isLoading &&
                    equipmentId === equip.id ? (
                        <Button variant="outlined" size="medium">
                            <CircularProgress size={22} />
                        </Button>
                    ) : equip.soldStatus === 1 ? (
                        <>
                            <Button
                                onClick={() =>
                                    equipmentSoldMutation.mutate({
                                        equipmentId: equip.id,
                                        status: 0,
                                    })
                                }
                                size="small"
                                startIcon={<SellIcon />}
                            >
                                {soldStatus === "success" &&
                                equipmentId === equip.id ? (
                                    <Typography
                                        sx={{ fontWeight: 600, color: "green" }}
                                    >
                                        UnSold
                                    </Typography>
                                ) : soldStatus === "error" &&
                                  equipmentId === equip.id ? (
                                    <Typography
                                        sx={{ fontWeight: 600, color: "red" }}
                                    >
                                        Unsold Failed
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{ color: "gray", fontWeight: 600 }}
                                    >
                                        Mark Unsold
                                    </Typography>
                                )}
                            </Button>
                        </>
                    ) : (
                        <Button
                            onClick={() =>
                                equipmentSoldMutation.mutate({
                                    equipmentId: equip.id,
                                    status: 1,
                                })
                            }
                            size="small"
                            startIcon={<SellIcon />}
                        >
                            {soldStatus === "success" &&
                            equipmentId === equip.id ? (
                                <Typography
                                    sx={{ fontWeight: 600, color: "green" }}
                                >
                                    Sold
                                </Typography>
                            ) : soldStatus === "error" &&
                              equipmentId === equip.id ? (
                                <Typography
                                    sx={{ fontWeight: 600, color: "red" }}
                                >
                                    Sold Failed
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ color: "gray", fontWeight: 600 }}
                                >
                                    Mark sold
                                </Typography>
                            )}
                        </Button>
                    )}
                </CardActions>
            </Card>
        </Grid>
    ));

    return (
        <>
            <ShowSnackbar
                open={alertInfo.open}
                message={alertInfo.message}
                handleClose={() =>
                    setAlertInfo((prev) => ({ ...prev, open: false }))
                }
            />

            <Grid container spacing={2}>
                {isLoading ? <CardSkeleton total={8} /> : listItems}
            </Grid>
        </>
    );
}
