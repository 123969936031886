import AddFormStepper from "@/components/app/equipment-forms/AddFormStepper";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";

function CreateEquipment() {
    return (
        <>
            <AppAppBar />
            <Container style={{ mt: { sm: 12, md: 15, xs: 3 } }}>
                <AddFormStepper />
            </Container>
        </>
    );
}

export default CreateEquipment;
