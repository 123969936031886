import { SoldEquipmentType } from "@/types/equipment-list";
import { formattedPrice, subStrText } from "@/utils";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import PendingIcon from "@mui/icons-material/Pending";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import { Fragment } from "react";

type InformationModalProps = {
    open: boolean;
    equipment: SoldEquipmentType;
    handleClose: () => void;
};

export default function InformationModal({
    open,
    equipment,
    handleClose,
}: InformationModalProps) {
    return (
        <Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="information-modal"
                open={open}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2, fontSize: 23 }}
                    id="information-modal"
                >
                    {equipment.title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box
                        sx={{
                            minWidth: 500,
                            overflow: "hidden",
                            mt: 2,
                            textAlign: "center",
                            border: 1,
                            borderColor: "#d4d4d8",
                            borderRadius: 2,
                            pl: { xs: 0, md: 2 },
                        }}
                    >
                        <Typography
                            component={"div"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Customer Price</p>
                            <p style={{ fontWeight: 700, color: "green" }}>
                                {formattedPrice(equipment.customerPrice)}
                            </p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Dealer Price</p>
                            <p style={{ fontWeight: 700, color: "green" }}>
                                {formattedPrice(equipment.dealerPrice)}
                            </p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Category</p>
                            <p>{subStrText(equipment?.category?.name, 20)}</p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Type</p>
                            <p>{equipment?.equipmentType?.name}</p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Manufacture</p>
                            <p title={equipment?.manufacturer?.name}>
                                {equipment?.manufacturer?.name || "n/a"}
                            </p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Condition</p>
                            <p title={equipment?.condition?.value}>
                                {subStrText(equipment?.condition?.value, 20) ||
                                    "n/a"}
                            </p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Zip Code</p>
                            <p>{equipment.zipCode || "n/a"}</p>
                        </Typography>

                        <Divider />

                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                px: { xs: 1, md: 2 },
                            }}
                        >
                            <p>Status</p>
                            {equipment.status === 1 ? (
                                <Button
                                    startIcon={<CheckBoxIcon />}
                                    size="small"
                                    disableElevation
                                    sx={{
                                        backgroundColor: "#93ca5c",

                                        color: "#f9fafb",
                                        ":hover": {
                                            backgroundColor: "#166534",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Approved
                                </Button>
                            ) : (
                                <Button
                                    startIcon={<PendingIcon />}
                                    size="small"
                                    disableElevation
                                    sx={{
                                        backgroundColor: "#f59e0b",
                                        color: "#f9fafb",
                                        ":hover": {
                                            backgroundColor: "#d97706",
                                        },
                                    }}
                                    variant="contained"
                                >
                                    Pending
                                </Button>
                            )}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose}>
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
