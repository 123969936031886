import { fetchCatalog } from "@/apis/catalog";
import DetailSkeleton from "@/components/shared/loaders/DetailSkeleton";
import { CatalogImageType } from "@/types/catalog-type";
import { subStrText, transformImageUrl } from "@/utils/index";
import CallIcon from "@mui/icons-material/Call";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SendIcon from "@mui/icons-material/Send";
import {
    Box,
    Breadcrumbs,
    Button,
    CardContent,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { NavLink, useParams } from "react-router-dom";
import {
    animateScroll as scroll,
    Element,
    Events,
    scroller,
} from "react-scroll";

const handleDragStart = (e: React.DragEvent<HTMLImageElement>) =>
    e.preventDefault();

function CatalogDetail() {
    const { id } = useParams();

    const [mainIndex, setMainIndex] = useState(0);

    const handleImageSelect = (image: any) => {
        setMainIndex(image);
    };

    const { data, isLoading } = useQuery(
        "single-catalog",
        () => fetchCatalog(id),
        {
            refetchOnWindowFocus: false,
        }
    );

    const catalog = data?.data.data;

    const items: any[] | undefined = [];

    const storeHTMLImages = (images: any[]) => {
        images?.map((img: any, index: number) =>
            items.push(
                <div className="item" key={index}>
                    <img
                        className="slideimg"
                        height="500px"
                        src={transformImageUrl(img.path)}
                        onDragStart={handleDragStart}
                    />
                </div>
            )
        );
    };

    data && storeHTMLImages(catalog.images);

    function scrollTop() {
        scroll.scrollToTop();
    }

    function scollToSpecs() {
        scroller.scrollTo("scroll-to-specifications", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    }

    useEffect(() => {
        return () => {
            scrollTop();
            Events.scrollEvent.remove("end");
        };
    }, []);

    return (
        <div>
            {isLoading ? (
                <DetailSkeleton />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12} xl={9}>
                        <Box
                            sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "6px",
                                p: 1.5,
                                mb: 5,
                                backgroundColor: "white",
                            }}
                        >
                            <Breadcrumbs aria-label="breadcrumb">
                                <NavLink
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                    color="inherit"
                                    to="/equipment"
                                >
                                    <HomeRoundedIcon />
                                </NavLink>
                                <NavLink
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                    to="/catalog"
                                >
                                    Catalog
                                </NavLink>
                                <Typography aria-current="page">
                                    Catalog Detail
                                </Typography>
                            </Breadcrumbs>

                            <Typography
                                className="brand-text-color"
                                sx={{
                                    mt: { sm: 1, xs: 2 },
                                    mb: 1,
                                    ml: { sm: 2, xs: 0 },
                                    fontWeight: { xs: 600, md: 700 },
                                    fontSize: {
                                        xs: 18,
                                        lg: 26,
                                    },
                                }}
                            >
                                {catalog.title}
                            </Typography>

                            <Grid
                                container
                                spacing={2}
                                sx={{ px: { sm: 2, xs: 0 } }}
                            >
                                <Grid item xs={12} md={12} lg={8}>
                                    <Box
                                        sx={{
                                            borderRadius: 2,
                                            overflow: "hidden",
                                        }}
                                    >
                                        {/* Image Gallery */}

                                        <Carousel
                                            thumbWidth={100}
                                            showArrows={true}
                                            emulateTouch
                                            renderThumbs={(children) =>
                                                catalog.images.map(
                                                    (
                                                        item: CatalogImageType,
                                                        index: number
                                                    ) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: {
                                                                    xs: "none",
                                                                    sm: "block",
                                                                },
                                                            }}
                                                        >
                                                            <img
                                                                src={transformImageUrl(
                                                                    item.path
                                                                )}
                                                                alt="Recycling Dealer Catalog Image"
                                                                style={{
                                                                    height: "3.5rem",
                                                                    width: "100%",
                                                                    borderRadius:
                                                                        "0.375rem",
                                                                    border: "1px solid #ccc",
                                                                    objectFit:
                                                                        "cover",
                                                                }}
                                                            />
                                                        </Box>
                                                    )
                                                )
                                            }
                                        >
                                            {catalog.images.map(
                                                (
                                                    image: CatalogImageType,
                                                    index: number
                                                ) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            overflow: "hidden",
                                                            borderRadius:
                                                                "0.375rem",
                                                            border: "1px solid #e5e5e5",
                                                        }}
                                                    >
                                                        <Box
                                                            component={"img"}
                                                            src={transformImageUrl(
                                                                image.path
                                                            )}
                                                            alt={
                                                                image.name ||
                                                                "Baling Wire Direct Image of Product"
                                                            }
                                                            sx={{
                                                                objectFit:
                                                                    "fill",
                                                                height: {
                                                                    sm: "24rem",
                                                                    xs: "19rem",
                                                                },
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </Carousel>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={4}>
                                    <Box sx={{ p: { md: 1, lg: 0 } }}>
                                        <Box
                                            sx={{
                                                overflow: "hidden",
                                                textAlign: "center",
                                                border: 1,
                                                borderColor: "#d4d4d8",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Category</p>
                                                <p>
                                                    {subStrText(
                                                        catalog.category.name,
                                                        22
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Manufacturer</p>
                                                <p>
                                                    {subStrText(
                                                        catalog.manufacturer
                                                            .name,
                                                        20
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Condition</p>
                                                <p>
                                                    {subStrText(
                                                        catalog?.condition
                                                            .value,
                                                        20
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Listing ID</p>
                                                <p>{catalog.listing_id}</p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Model</p>
                                                <p>{catalog.model_number}</p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Lead Time</p>
                                                <p>{catalog.lead_time} Weeks</p>
                                            </Typography>

                                            <Divider sx={{ mb: 2 }} />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <Button
                                                    fullWidth
                                                    onClick={scollToSpecs}
                                                    sx={{
                                                        mb: 2,
                                                        border: 1,
                                                        borderColor: "#065f46",
                                                        color: "#065f46",
                                                    }}
                                                    variant="text"
                                                >
                                                    Read All Specs
                                                </Button>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "6px",
                                p: 2,
                                mb: 4,
                                backgroundColor: "white",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <Typography variant="h5" sx={{ mb: 2 }}>
                                            Equipment Description
                                        </Typography>
                                        <Typography
                                            dangerouslySetInnerHTML={{
                                                __html: catalog.description,
                                            }}
                                            sx={{ textAlign: "justify" }}
                                        ></Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box
                            sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "6px",
                                mb: 4,
                                p: { lg: 1 },
                                backgroundColor: "white",
                            }}
                        >
                            <Element name="scroll-to-specifications">
                                <Box>
                                    <CardContent>
                                        <Typography variant="h6">
                                            Specifications
                                        </Typography>
                                        <Box
                                            sx={{
                                                overflow: "hidden",
                                                mt: 2,
                                                textAlign: "center",
                                                border: 1,
                                                borderColor: "#d4d4d8",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Category</p>
                                                <p>
                                                    Single Ram Open End Auto-Tie
                                                    Horizontal Balers
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Manufacturer</p>
                                                <p>
                                                    Marathon Equipment Company
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Condition</p>
                                                <p>Refurbished (Up To Spec)</p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Listing ID</p>
                                                <p>RCB8350</p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Model</p>
                                                <p>
                                                    {subStrText(
                                                        "Mercury 500",
                                                        20
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: 2,
                                                }}
                                            >
                                                <p>Lead Time</p>
                                                <p>2 Weeks</p>
                                            </Typography>

                                            <Divider />
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Element>
                        </Box>

                        <Box
                            sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "6px",
                                p: 2,
                                mb: 4,
                                backgroundColor: "white",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box className="editor">
                                        <Typography variant="h6" sx={{ mb: 2 }}>
                                            Dealer/Re-seller Terms & Conditions
                                        </Typography>
                                        <Typography
                                            component={"p"}
                                            sx={{ fontSize: 18 }}
                                        >
                                            {catalog.dealer.tnc}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} xl={3}>
                        <Box
                            sx={{
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                borderRadius: "6px",
                                p: 2,
                                backgroundColor: "white",
                            }}
                        >
                            <Box
                                sx={{
                                    display: { sm: "flex", lg: "block" },
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        mb: 1,
                                    }}
                                >
                                    Dealer Pricing
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: 20,
                                        fontWeight: 700,
                                        mb: 1,
                                        color: "#065f46",
                                    }}
                                >
                                    {catalog.pricing}
                                </Typography>
                            </Box>
                            <Typography
                                sx={{
                                    mt: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: 2,
                                    fontSize: 16,
                                    fontWeight: 400,
                                }}
                            >
                                <LocationOnIcon style={{ fontSize: 20 }} />
                                {catalog.dealer.location}
                            </Typography>
                            <Typography
                                sx={{ mt: 2, fontSize: 18, fontWeight: 600 }}
                            >
                                Dealer Information
                            </Typography>
                            <Typography sx={{ mt: 1, fontSize: 16 }}>
                                <span style={{ fontWeight: 600 }}>Email: </span>{" "}
                                {catalog.dealer?.email || "not available"}
                            </Typography>
                            <Typography sx={{ mt: 1, fontSize: 16 }}>
                                <span style={{ fontWeight: 600 }}>Name: </span>
                                {catalog.dealer?.name || "not available"}
                            </Typography>
                            <Typography
                                sx={{ mt: 3, fontSize: 18, fontWeight: 600 }}
                            >
                                Action Compaction
                            </Typography>
                            <Typography sx={{ mt: 1, mb: 2, fontSize: 16 }}>
                                {catalog.map}
                            </Typography>

                            <Divider />

                            <Typography
                                sx={{
                                    mt: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: 2,
                                }}
                                alignItems="center"
                            >
                                <CallIcon />
                                {catalog.dealer.display_number ? (
                                    <PatternFormat
                                        className="brand-text-color"
                                        disabled
                                        value={catalog.dealer.display_number}
                                        format="+1 (###) ### - ####"
                                        style={{
                                            border: "none",
                                            fontSize: "1.3rem",
                                            fontWeight: 700,

                                            backgroundColor: "inherit",
                                        }}
                                    />
                                ) : (
                                    "Not Available"
                                )}
                            </Typography>

                            <Typography sx={{ mt: 1 }}>
                                Send Seller a Message
                            </Typography>

                            <TextField
                                fullWidth
                                sx={{ mt: 2 }}
                                id="outlined-basic"
                                value={"Hi, is this still available?"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SendIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                fullWidth
                                sx={{
                                    mt: 2,
                                    fontSize: 15,
                                    backgroundColor: "#93ca5c",
                                    color: "white",
                                    ":hover": { backgroundColor: "#10b981" },
                                }}
                                variant="text"
                                endIcon={<SendIcon style={{ fontSize: 20 }} />}
                            >
                                Send
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default CatalogDetail;
