import { postEquipmentMedia, saveEquipmentOptions } from "@/apis/equipment";
import { resetEquipmentState } from "@/features/dealerSlice";
import { resetMediaImages } from "@/features/equipmentSlice";
import { AddEquipmentType } from "@/store/types/store-types";
import { EquipmentAction } from "@/types/create-equipment";
import { useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useEquipmentOptionHook(refetch: () => void) {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const state = useSelector((state: AddEquipmentType) => state.equipmentAdd);

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        message: "",
    });

    const equipmentOptionMutation = useMutation(
        (argument: EquipmentAction) => {
            const { equipmentId }: EquipmentAction = argument;

            const formData = new FormData();

            for (const key in state.optionsInput) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        state.optionsInput,
                        key
                    )
                ) {
                    const value = state.optionsInput[key];
                    formData.append(`option[${key}]`, value);
                }
            }

            state.resources.pdfBrochure &&
                formData.append("pdfBrochure", state.resources.pdfBrochure);

            state.resources.pdfSheet &&
                formData.append("pdfSheet", state.resources.pdfSheet);

            state.embedVideos?.length >= 1 &&
                state.embedVideos.forEach((element) => {
                    formData.append("video-embed[]", element);
                });

            const payload = {
                equipmentId: equipmentId,
                formData: formData,
            };

            return saveEquipmentOptions(payload);
        },
        {
            onSuccess: ({ data }, argument) => {
                const { action } = argument;

                setAlertInfo({
                    open: true,
                    message: data.message,
                });
                dispatch(resetEquipmentState());
                if (action === "add") {
                    navigate("/equipment/list");
                }
                if (action === "edit") {
                    refetch();
                }
            },
            onError: () => {
                setAlertInfo({
                    open: true,
                    message: "Something went wrong, try again",
                });
            },
        }
    );

    const equipmentMediaMutation = useMutation(
        (equipmentId: string | null | undefined) => {
            const formData = new FormData();

            state.mediaImages.forEach((item) => {
                formData.append("files[]", item.file);
            });

            const payload = {
                equipmentId: equipmentId,
                formData: formData,
            };

            return postEquipmentMedia(payload);
        },
        {
            onSuccess: ({ data }) => {
                setAlertInfo({
                    open: true,
                    message: data.message,
                });
                dispatch(resetMediaImages());
            },
        }
    );

    return {
        equipmentOptionMutation,
        equipmentMediaMutation,
        alertInfo,
        setAlertInfo,
    };
}
