import { fetchEquipmentById } from "@/apis/equipment";
import ShowSnackbar from "@/components/shared/modals/Snackbar";
import {
    mutateInformationData,
    resetEquipmentState,
} from "@/features/equipmentSlice";
import useEquipmentHook from "@/hook/useEquipmentHook";
import useEquipmentOptionHook from "@/hook/useEquipmentOptionHook";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Backdrop,
    CircularProgress,
    StepButton,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdditionalInformation from "./AdditionalInformation";
import Information from "./Information";
import Media from "./Media";
import Resources from "./Resources";
import TechnicalData from "./TechnicalData";

const steps = [
    "Information",
    "Technical Data",
    "Addition Information",
    "Resources",
    "Media",
];

function AddFormStepper() {
    /** Local state for the component */
    const childRef = useRef<any>();

    /** Redux Global State Hook */
    const dispatch = useDispatch();

    /** built in package hooks from react-router-dom  */
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [technicalData, setTechnicalData] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const { data, isLoading, refetch } = useQuery(
        ["single-used-equipment", searchParams.get("id")],
        () => fetchEquipmentById(searchParams.get("id")),
        {
            enabled: !!searchParams.get("id"),
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            onSuccess: ({ data }) => {
                dispatch(mutateInformationData(data.data));
                setTechnicalData(data.data.technicalData);
                setAdditionalInfo(data.data.additionalInformation);
                setIsDisabled(false);
            },
            onError(err: AxiosError) {
                if (err.response?.status === 404) {
                    navigate("/equipment/add");
                    setAlertInfo({
                        open: true,
                        message:
                            "We did not find the equipment, please consider creating one",
                    });
                }
            },
            retry: 1,
        }
    );

    /** custom hook for handing equipment options */
    const {
        equipmentOptionMutation,
        equipmentMediaMutation,
        alertInfo,
        setAlertInfo,
    } = useEquipmentOptionHook(refetch);

    /** custom hook to handle equipment draft and toggle equipment sold */
    const {
        equipmentDraftMutation,
        activeStep,
        setActiveStep,
        alertInfo: addEquipmentAlert,
    } = useEquipmentHook();

    useEffect(() => {
        if (searchParams.get("id")) {
            refetch();
        }

        if (searchParams && !searchParams.get("id")) {
            dispatch(resetEquipmentState());
        }
    }, []);

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={
                    equipmentOptionMutation.isLoading ||
                    equipmentDraftMutation.isLoading ||
                    isLoading
                }
            >
                <CircularProgress size={60} color="inherit" />

                <Typography sx={{ ml: 2 }}>Hang on a moment please</Typography>
            </Backdrop>

            <ShowSnackbar
                open={alertInfo.open || addEquipmentAlert.open}
                message={alertInfo.message || addEquipmentAlert.message}
                handleClose={() => setAlertInfo({ open: false, message: "" })}
            />

            <Box sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                color="inherit"
                                disabled={isDisabled}
                                onClick={() => setActiveStep(index)}
                            >
                                <Typography
                                    sx={{
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    {label}
                                </Typography>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Information
                                ref={childRef}
                                onChangeState={(data) =>
                                    equipmentDraftMutation.mutate(data)
                                }
                            />
                        )}

                        {activeStep === 1 && (
                            <TechnicalData data={technicalData} />
                        )}

                        {activeStep === 2 && (
                            <AdditionalInformation data={additionalInfo} />
                        )}

                        {activeStep === 3 && <Resources />}

                        {activeStep > 3 && (
                            <Media
                                savedImages={data?.data.data.mediaImages}
                                onStatusChanged={() => refetch()}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            pt: 2,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            sx={{
                                px: 4,
                                py: 1,
                                display: activeStep === 0 ? "none" : "block",
                            }}
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={() =>
                                setActiveStep(
                                    (prevActiveStep) => prevActiveStep - 1
                                )
                            }
                            variant="outlined"
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        <Box sx={{ mb: 4 }}>
                            {activeStep === steps.length - 1 ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        mt: 4,
                                    }}
                                >
                                    <Button
                                        sx={{ px: 5, py: 1 }}
                                        variant="contained"
                                        onClick={() =>
                                            Promise.all([
                                                equipmentOptionMutation.mutate({
                                                    equipmentId:
                                                        searchParams.get("id"),
                                                    action: "add",
                                                }),
                                                equipmentMediaMutation.mutate(
                                                    searchParams.get("id")
                                                ),
                                            ])
                                        }
                                    >
                                        Publish
                                    </Button>
                                </Box>
                            ) : (
                                <>
                                    {activeStep === 0 ? (
                                        <>
                                            {data?.data.data.hasEquipment ? (
                                                <LoadingButton
                                                    sx={{ px: 5, py: 1 }}
                                                    loading={isLoading}
                                                    variant="contained"
                                                    onClick={() =>
                                                        setActiveStep(
                                                            (prevActiveStep) =>
                                                                prevActiveStep +
                                                                1
                                                        )
                                                    }
                                                >
                                                    Continue
                                                </LoadingButton>
                                            ) : (
                                                <LoadingButton
                                                    sx={{ px: 5, py: 1 }}
                                                    loading={
                                                        equipmentDraftMutation.isLoading
                                                    }
                                                    variant="contained"
                                                    onClick={() =>
                                                        childRef?.current.onSaveAction()
                                                    }
                                                >
                                                    Create Draft
                                                </LoadingButton>
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            sx={{ px: 5, py: 1 }}
                                            variant="contained"
                                            onClick={() =>
                                                setActiveStep(
                                                    (prevActiveStep) =>
                                                        prevActiveStep + 1
                                                )
                                            }
                                        >
                                            Next
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </>
            </Box>
        </>
    );
}

export default AddFormStepper;
