import useAuthUserHook from "@/hook/useAuthUserHook";
import { DealerStateType } from "@/store/types/store-types";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import GradingIcon from "@mui/icons-material/Grading";
import ListIcon from "@mui/icons-material/List";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import {
    Box,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Typography,
} from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const settings = [
    {
        id: 1,
        name: "Profile",
        route: "/profile",
        target: false,
        icon: <AccountCircleOutlinedIcon />,
    },
    {
        id: 2,
        name: "Billing",
        target: false,
        route: "/billing",
        icon: <MonetizationOnOutlinedIcon />,
    },
    {
        id: 3,
        name: "Leads",
        target: false,
        route: "/leads",
        icon: <TextSnippetIcon />,
    },
    {
        id: 4,
        name: "Try IOS App",
        target: true,
        route: "https://apps.apple.com/ca/app/recyclingbalers-dealer-app/id1555761472",
        icon: <PhoneIphoneOutlinedIcon />,
    },
    {
        id: 5,
        name: "Try Android App",
        target: true,
        route: "https://play.google.com/store/apps/details?id=io.recyclingbalers.dealer",
        icon: <PhoneAndroidOutlinedIcon />,
    },
];

export default function BottomNavbar() {
    const navigate = useNavigate();
    const { authUserAvatar } = useAuthUserHook();

    const state = useSelector((state: DealerStateType) => state.dealerSlice);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    return (
        <Paper
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 999,
            }}
            elevation={10}
        >
            <BottomNavigation
                sx={{ height: "4rem", mx: 2 }}
                showLabels
                onChange={(event, newValue) => {
                    navigate("/" + newValue);
                }}
            >
                <BottomNavigationAction
                    value="equipment/add"
                    label={
                        <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 }, width: "100%" }}
                        >
                            Sell Equipment
                        </Typography>
                    }
                    icon={<AddIcon sx={{ fontSize: { xs: 18, sm: 22 } }} />}
                />
                <BottomNavigationAction
                    value="equipment"
                    label={
                        <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 }, width: "100%" }}
                        >
                            Used List
                        </Typography>
                    }
                    icon={<ListIcon />}
                />
                <BottomNavigationAction
                    value="equipment/sold"
                    label={
                        <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 }, width: "100%" }}
                        >
                            Sold List
                        </Typography>
                    }
                    icon={<GradingIcon />}
                />

                <BottomNavigationAction
                    value="catalog"
                    label={
                        <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 }, width: "100%" }}
                        >
                            Catalog
                        </Typography>
                    }
                    icon={<FilterListIcon />}
                />
            </BottomNavigation>
        </Paper>
    );
}

type UserMenuBarProps = {
    showDropdown: any;
    handleClose: () => void;
};

export function UserMenuBar({ showDropdown, handleClose }: UserMenuBarProps) {
    const { processLogout } = useAuthUserHook();

    return (
        <>
            <Menu
                sx={{ ml: { xs: 1, lg: -5, xl: -22 }, mt: 6 }}
                id="menu-appbar"
                anchorEl={showDropdown}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                open={Boolean(showDropdown)}
                onClose={handleClose}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting.id}>
                        <Box
                            component={NavLink}
                            to={setting.route}
                            target={setting.target ? "_blank" : ""}
                            sx={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <ListItemIcon>{setting.icon}</ListItemIcon>
                            <ListItemText primary={setting.name} />
                        </Box>
                    </MenuItem>
                ))}
                <MenuItem onClick={() => processLogout.mutate()}>
                    <Box
                        sx={{
                            textDecoration: "none",
                            color: "inherit",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <ListItemIcon>
                            <LogoutOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Logout"} />
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
}
