import api from "@/config/api";
import { ToggleType } from "@/types/option-type";

export const saveEquipmentOptions = async (payload: any) => {
    const response = await api().post(
        "dealer/equipment/add/option/" + payload.equipmentId,
        payload.formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return response;
};

export const saveEquipmentDrafts = async (payload: any) => {
    const response = await api().post("dealer/equipment/add", payload, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response;
};

export const updateEquipment = async (payload: any) => {
    const response = await api().post(
        "dealer/equipment/update/" + payload.equipmentId,
        payload.formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return response;
};

export const makeImageCover = async (payload: any) => {
    const response = await api().post(
        "dealer/equipment/set/cover/image/",
        payload
    );

    return response;
};

export const deleteEquipmentImage = async (params: any) => {
    const response = await api().delete(
        "dealer/equipment/delete/image/" +
            params.equipmentId +
            "/" +
            params.imageId
    );

    return response;
};

export const fetchUsedEquipment = () => api().get("dealer/equipment/used");

export const fetchEquipmentById = (equipmentId: string | null) =>
    api().get("dealer/equipment/used/" + equipmentId);

export const postEquipmentMedia = async (payload: any) => {
    const response = await api().post(
        "dealer/equipment/upload/image/" + payload.equipmentId,
        payload.formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    return response;
};

export const fetchEquipments = () => api().get("dealer/equipment/list");

export const toggleSoldEquipment = (payload: ToggleType) => {
    return api().get(
        `dealer/equipment/sold/${payload.equipmentId}/${payload.status}`
    );
};
