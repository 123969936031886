import { mutateOptionsInput } from "@/features/equipmentSlice";
import { AddEquipmentType } from "@/store/types/store-types";
import { AdditionalInformationProps } from "@/types/create-equipment";
import { removeHTMLTags } from "@/utils";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function AdditionalInformation({
    data,
}: AdditionalInformationProps) {
    const dispatch = useDispatch();

    const optionState = useSelector(
        (state: AddEquipmentType) => state.equipmentAdd.optionsInput
    );

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        selectedItem: number
    ) => {
        dispatch(
            mutateOptionsInput({
                id: selectedItem,
                value: event.target.value,
            })
        );
    };

    return (
        <>
            <Box
                sx={{
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "white",
                }}
            >
                {data.map((item, index) => {
                    // Only render TextField components if input_type is "text"
                    if (item.input_type === "text") {
                        return (
                            <FormControl
                                key={index}
                                sx={{
                                    mt: 2,
                                    ml: 2,
                                    width: { xs: `100%`, sm: `49%` },
                                }}
                            >
                                <TextField
                                    id={item.identifier + "id-" + item.id}
                                    label={item.name}
                                    value={optionState[item.id]}
                                    onChange={(event) =>
                                        handleChange(event, item.id)
                                    }
                                    required={
                                        item.is_required === 1 ? true : false
                                    }
                                    helperText={
                                        <Typography
                                            component={"span"}
                                            sx={{ fontSize: 11 }}
                                        >
                                            {removeHTMLTags(item.description)}
                                        </Typography>
                                    }
                                    variant="outlined"
                                />
                            </FormControl>
                        );
                    }
                    return null; // Skip rendering if input_type is not "text"
                })}
            </Box>
        </>
    );
}
