import DealerCatalog from "@/components/app/equipment-lists/catalog/MainCatalog";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";
import { Typography } from "@mui/material";

function CatalogView() {
    return (
        <>
            <AppAppBar />

            <Container>
                <Typography
                    sx={{
                        color: "#93ca5c",
                        fontSize: { md: 35, xs: 25 },
                        fontWeight: 700,
                        mb: { sm: 2, xs: 1 },
                        textAlign: "center",
                    }}
                >
                    Dealer Catalog
                </Typography>
                <DealerCatalog />
            </Container>
        </>
    );
}

export default CatalogView;
