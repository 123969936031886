import App from "@/App";
import RequiredAuth from "@/components/auth/RequiredAuth";
import RedirectToken from "@/components/auth/RedirectToken";
import BillingView from "@/pages/billing-view";
import CatalogDetailView from "@/pages/catalog-detail-view";
import CatalogView from "@/pages/catalog-view";
import CreateEquipment from "@/pages/create-equipment";
import EditEquipment from "@/pages/edit-equipment";
import LeadsView from "@/pages/leads-view";
import Login from "@/pages/login";
import ProfileView from "@/pages/profile-view";
import SoldListings from "@/pages/sold-listings";
import UsedListings from "@/pages/used-listings";
import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
    {
        path: "/init/auth/:token",
        element: <RedirectToken />,
    },
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/equipment/add",
        element: (
            <RequiredAuth>
                <CreateEquipment />
            </RequiredAuth>
        ),
    },
    {
        path: "/equipment/edit/:id",
        element: (
            <RequiredAuth>
                <EditEquipment />
            </RequiredAuth>
        ),
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/equipment",
        element: (
            <RequiredAuth>
                <UsedListings />
            </RequiredAuth>
        ),
    },
    {
        path: "/equipment/sold",
        element: (
            <RequiredAuth>
                <SoldListings />
            </RequiredAuth>
        ),
    },
    {
        path: "/catalog",
        element: <CatalogView />,
    },
    {
        path: "/catalog/:id",
        element: <CatalogDetailView />,
    },
    {
        path: "/billing",
        element: (
            <RequiredAuth>
                <BillingView />
            </RequiredAuth>
        ),
    },
    {
        path: "/profile",
        element: (
            <RequiredAuth>
                <ProfileView />
            </RequiredAuth>
        ),
    },
    {
        path: "/leads",
        element: (
            <RequiredAuth>
                <LeadsView />
            </RequiredAuth>
        ),
    },
]);
