import Profile from "@/components/account/Profile";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";

function ProfileView() {
    return (
        <>
            <AppAppBar />

            <Container>
                <Profile />
            </Container>
        </>
    );
}

export default ProfileView;
