import QuotationList from "@/components/app/equipment-lists/Leads";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";
import { Typography } from "@mui/material";

function LeadsView() {
    return (
        <>
            <AppAppBar />
            <Container style={{ px: 8 }}>
                <Typography
                    sx={{
                        color: "#93ca5c",
                        fontSize: 35,
                        fontWeight: 700,
                        mb: 2,
                        mt: 12,
                        textAlign: "center",
                    }}
                >
                    Application Leads
                </Typography>
                <QuotationList />
            </Container>
        </>
    );
}

export default LeadsView;
