import api from "@/config/api";
import { SearchQuery } from "@/types/catalog-type";
import _debounce from "lodash.debounce";
import { ChangeEvent, useCallback, useState } from "react";

export default function useAutoCompleteHook() {
    /**
     * Auto Complete address add feature section
     *
     * In this section we will be implementing the auto complete address feature.
     *
     */
    const [showManualAddress, setShowManualAddress] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchedAddress, setSearchedAddress] = useState<SearchQuery[]>([]);
    const [autoCompleteLoader, setAutoCompleteLoader] = useState(false);

    const debounceFn = useCallback(_debounce(handleDebounceFn, 700), []);

    function handleDebounceFn(query: string) {
        if (query) {
            api()
                .post("/dealer/address/search", {
                    query: query,
                })
                .then(({ data }) => {
                    console.log("data is", data);

                    setSearchedAddress(data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setAutoCompleteLoader(false);
                });
        } else {
            setAutoCompleteLoader(false);
        }
    }

    function handleSearchQuery(event: ChangeEvent<HTMLInputElement>) {
        const newQuery = event.currentTarget.value;

        setSearchQuery(newQuery);
        setAutoCompleteLoader(true);

        debounceFn(newQuery);
    }

    return {
        showManualAddress,
        setShowManualAddress,
        searchQuery,
        searchedAddress,
        autoCompleteLoader,
        handleSearchQuery,
    };
}
