import { useTheme } from "@mui/material/styles";
export default function useResponsiveHook() {
    const theme = useTheme();

    const xs = theme.breakpoints.down("xs");
    const sm = theme.breakpoints.down("sm");
    const md = theme.breakpoints.down("md");
    const lg = theme.breakpoints.down("lg");
    const xl = theme.breakpoints.down("xl");

    return {
        xs,
        sm,
        md,
        lg,
        xl,
    };
}
