import api from "@/config/api";
import { mutateDealerInfo, setDealerToken } from "@/features/dealerSlice";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AxiosError } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

function Copyright(props: any) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://recyclingbalers.com/">
                Recycling Balers
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

type FormValues = {
    email: string;
    password: string;
    rememberMe: boolean;
};

export default function DealerLogin() {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { register, handleSubmit, reset } = useForm<FormValues>();

    const [loginError, setLoginError] = useState<string>("");

    const mutate = useMutation(
        (payload: FormValues) => {
            return api().post("/dealer/login", payload);
        },
        {
            onSuccess: ({ data }) => {
                reset();
                dispatch(setDealerToken(data.token));
                dispatch(mutateDealerInfo(data.user));
                navigate("/equipment");
            },
            onError: (error: AxiosError) => {
                setLoginError(error.response?.data?.message as any);
            },
            retry: 0,
        }
    );

    return (
        <Container maxWidth="xs">
            <CssBaseline />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{ m: 2, width: "200px", height: "40px" }}
                    component={"img"}
                    src="https://recyclingbalers.s3.amazonaws.com/logo.png"
                ></Box>

                <Box
                    component="form"
                    onSubmit={handleSubmit((data) => mutate.mutate(data))}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        {...register("email", {
                            required: "Please enter your email",
                        })}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        {...register("password", {
                            required: "Please enter your password",
                        })}
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                {...register("rememberMe")}
                                color="primary"
                            />
                        }
                        label="Remember me"
                    />

                    {loginError && (
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {loginError}
                        </Alert>
                    )}

                    <LoadingButton
                        type="submit"
                        fullWidth
                        loading={mutate.isLoading}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </LoadingButton>
                    <Grid container>
                        {/* <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid> */}
                        <Grid item>
                            <NavLink
                                style={{
                                    textDecoration: "none",
                                    color: "#93ca5c",
                                    fontWeight: 700,
                                }}
                                target={"_blank"}
                                to="https://recyclingbalers.com/dealer/register"
                            >
                                {"Don't have an account? Sign Up"}
                            </NavLink>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}
