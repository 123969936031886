import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./router/index";
import store from "./store/store";

const queryClient = new QueryClient();

const theme = createTheme({
    palette: {
        primary: {
            main: "#93ca5c",
        },
        secondary: {
            main: "#B09F19",
        },
    },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <RouterProvider router={router} />
                </Provider>

                {/* <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                /> */}
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>
);
