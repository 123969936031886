import {
    EquipmentState,
    InformationFormValues,
} from "@/store/types/store-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the initial state object
const initialState: EquipmentState = {
    resources: {
        pdfBrochure: null,
        pdfSheet: null,
    },
    embedVideos: [],
    mediaImages: [],
    information: {
        id_equipment_type: "",
        id_category: "",
        id_equipment_condition: "",
        id_manufacturer: "",
        title: "",
        model_number: "",
        price: null,
        msrp: null,
        state: "",
        address: "",
        weight: null,
        feet: null,
        trucks: null,
        zipcode: null,
        lead_time: null,
        description: "",
        terms: "",
        used: 0,
    },
    optionsInput: [],
};

export const equipmentSlice = createSlice({
    name: "AddEquipment",
    initialState,
    reducers: {
        mutateOptionsInput: (
            state,
            { payload }: PayloadAction<{ id: number; value: any }>
        ) => {
            state.optionsInput[payload.id] = payload.value;
        },

        setSavedOptions: (state, { payload }) => {
            state.optionsInput = payload;
        },

        mutateInformationField: (
            state,
            action: PayloadAction<{
                field: keyof InformationFormValues;
                value: any;
            }>
        ) => {
            const { field, value } = action.payload;
            // Use type assertion to tell TypeScript that field is a valid key of InformationState
            (state.information as any)[field] = value;
        },

        mutateInformationData: (state, { payload }) => {
            state.information.address = payload.address;
            state.information.description = payload.description;
            state.information.feet = payload.feet;
            state.information.id_category = payload.id_category;
            state.information.id_equipment_condition =
                payload.id_equipment_condition;
            state.information.id_equipment_type = payload.id_equipment_type;
            state.information.id_manufacturer = payload.id_manufacturer;
            state.information.lead_time = payload.lead_time;
            state.information.model_number = payload.model_number;
            state.information.msrp = payload.msrp;
            state.information.price = payload.price;
            state.information.state = payload.state;
            state.information.terms = payload.terms;
            state.information.title = payload.title;
            state.information.trucks = payload.trucks;
            state.information.weight = payload.weight;
            state.information.zipcode = payload.zipcode;
            state.information.used = payload.used === 1 ? true : false;
        },

        mutateBrochureResource: (
            state,
            { payload }: PayloadAction<{ file: File }>
        ) => {
            state.resources.pdfBrochure = payload.file;
        },

        mutatePDFResource: (
            state,
            { payload }: PayloadAction<{ file: File }>
        ) => {
            state.resources.pdfSheet = payload.file;
        },

        mutateUsedState: (state, { payload }) => {
            state.information.used = payload;
        },

        mutateEmbedVideos: (state, { payload }) => {
            // Create a temporary element (e.g., a div) to parse the iframe HTML string
            const tempElement = document.createElement("div");
            tempElement.innerHTML = payload.embedCode;

            // Access the iframe element within the temporary element
            const iframeElement = tempElement.querySelector("iframe");

            // Check if the iframe element exists
            if (iframeElement) {
                const src = iframeElement.getAttribute("src");
                state.embedVideos.push(src as string);
            } else {
                const videoId = payload.embedCode
                    .split("/")
                    .pop()
                    .split("?")[0];
                // Construct the full embed URL
                const embedUrl = `https://www.youtube.com/embed/${videoId}`;
             
                state.embedVideos.push(embedUrl);
            }
        },

        mutateMediaImages: (state, { payload }) => {
            state.mediaImages = payload;
        },

        resetMediaImages: (state) => {
            state.mediaImages = [];
        },

        resetEquipmentState: () => initialState,
    },
});

// Action creators are generated for each case reducer function
export const {
    mutateOptionsInput,
    mutateBrochureResource,
    mutatePDFResource,
    mutateInformationField,
    mutateInformationData,
    resetEquipmentState,
    mutateEmbedVideos,
    setSavedOptions,
    mutateUsedState,
    mutateMediaImages,
    resetMediaImages
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
