import { CatalogEquipmentType, CatalogFilterType } from "@/types/catalog-type";
import { formattedPrice, subStrText, transformImageUrl } from "@/utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";

type CatalogList = {
    data: {
        pages: {
            data: {
                equipment: CatalogEquipmentType;
                catalog_filters: CatalogFilterType;
            };
        }[];
    };
};

function CatalogList({ data }: CatalogList) {
    return (
        <>
            {data?.pages.map((group, index) => (
                <Fragment key={index}>
                    {group.data.equipment.data.map((catalog) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={catalog.id}
                        >
                            <NavLink
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to={`/catalog/${catalog.id}`}
                            >
                                <Card
                                    variant="elevation"
                                    sx={{
                                        boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                                        transition:
                                            "box-shadow 0.2s ease-in-out",
                                        cursor: "pointer",
                                        "&:hover": {
                                            boxShadow: `0 4px 28px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22)`,
                                        },
                                    }}
                                >
                                    <CardMedia
                                        sx={{
                                            objectFit:
                                                catalog?.coverImage?.includes(
                                                    "not-found"
                                                )
                                                    ? "contain"
                                                    : "cover",
                                        }}
                                        component="img"
                                        height="200"
                                        image={transformImageUrl(
                                            catalog.coverImage
                                        )}
                                        alt={
                                            "Recycling Baler Image of " +
                                            catalog.title
                                        }
                                    />

                                    <CardContent>
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 600,
                                            }}
                                            gutterBottom
                                        >
                                            {catalog.condition?.value}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: { sm: 25, xs: 20 },
                                                lineHeight: `2.25rem`,
                                                color: "#93ca5c",
                                            }}
                                            gutterBottom
                                        >
                                            {catalog.msrp
                                                ? formattedPrice(catalog.msrp)
                                                : "Call for pricing"}
                                        </Typography>

                                        <Typography
                                            variant="subtitle1"
                                            gutterBottom
                                            color="textSecondary"
                                            sx={{
                                                fontWeight: 500,
                                                fontSize: 14,
                                            }}
                                        >
                                            {subStrText(catalog.title, 50)}
                                        </Typography>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                mb: 2,
                                                mt: 1,
                                            }}
                                        >
                                            <Typography sx={{ fontSize: 12 }}>
                                                {catalog.map}
                                            </Typography>

                                            <Typography sx={{ fontSize: 11 }}>
                                                {Math.ceil(catalog.distance)}{" "}
                                                Mi. Away
                                            </Typography>
                                        </Box>

                                        <Button
                                            variant="outlined"
                                            sx={{
                                                backgroundColor: "#93ca5c",
                                                border: "none",
                                                color: "white",
                                                ":hover": {
                                                    backgroundColor: "#93ca5c",
                                                    border: "none",
                                                },
                                            }}
                                            fullWidth
                                        >
                                            View Equipment
                                        </Button>
                                    </CardContent>
                                </Card>
                            </NavLink>
                        </Grid>
                    ))}
                </Fragment>
            ))}
        </>
    );
}

export default CatalogList;
