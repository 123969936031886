import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { mutateDealerInfo, setDealerToken, unsetDealerToken } from "@/features/dealerSlice";
import api from '@/config/api';
import { useQuery } from 'react-query';
import { Card, LinearProgress } from '@mui/material';

const fetchUser = () =>
    api().get("dealer/user");

function RedirectToken() {
    const dispatch = useDispatch();
    const { token } = useParams();
    const navigate = useNavigate();
    dispatch(setDealerToken(token));

    if (!token) {
        window.location.href = "https://recyclingbalers.com/dealer/login";
    }

    const {
        data,
        isLoading,
        error,
    } = useQuery(
        ["get-user"],
        fetchUser,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            retry: 0,
            onSuccess: (data) => {
                dispatch(mutateDealerInfo(data?.data?.dealer));
                navigate("/equipment");
            },
            onError: () => {
                dispatch(unsetDealerToken());
                window.location.href = "https://recyclingbalers.com/dealer/login";
            }
        }
    );

    return <Card variant="outlined" sx={{ width: "100%", height: "100vh" }}>
        {isLoading && (
            <LinearProgress />
        )}
    </Card>
}

export default RedirectToken