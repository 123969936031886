import { mutateOptionsInput } from "@/features/equipmentSlice";
import { AddEquipmentType } from "@/store/types/store-types";
import { TechnicalDataProps } from "@/types/create-equipment";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    SelectChangeEvent
} from "@mui/material";
import Select from "@mui/material/Select";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

function TechnicalData({ data }: TechnicalDataProps) {
    const dispatch = useDispatch();

    const optionState = useSelector(
        (state: AddEquipmentType) => state.equipmentAdd.optionsInput
    );

    const handleChange = (event: SelectChangeEvent, itemId: number) => {
        dispatch(
            mutateOptionsInput({
                id: itemId,
                value: event.target.value,
            })
        );
    };

    return (
        <>

      
            <Box
                sx={{
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "white",
                }}
            >
                {data.map((item, index) => (
                    <Fragment key={index}>
                        {item.input_type === "radio" && (
                            <FormControl
                                sx={{ width: "48%", marginTop: 3, ml: 2 }}
                            >
                                <FormLabel
                                    id="technical-data-radio-type"
                                    color={"primary"}
                                >
                                    {item.name}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-technical-data-radio-type"
                                    name="row-radio-buttons-group"
                                    value={optionState[item.id]}
                                    onChange={(event) => {
                                        handleChange(event, item.id);
                                    }}
                                >
                                    {item.values.map((value, valueIndex) => (
                                        <FormControlLabel
                                            key={valueIndex}
                                            value={value.id || ""}
                                            control={<Radio />}
                                            label={value.value}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        )}

                        {item.input_type !== "radio" && (
                            <FormControl
                                sx={{ width: "48%", marginTop: 3, ml: 2 }}
                                variant="outlined"
                            >
                                <InputLabel
                                    id={`equipment-category-select-label-${index}`}
                                >
                                    {item.name}
                                </InputLabel>

                                <Select
                                    labelId={`equipment-category-select-label-${index}`}
                                    id={`equipment-${item.identifier}`}
                                    label="Equipment Category"
                                    defaultValue={""}
                                    value={optionState[item.id]}
                                    onChange={(event) =>
                                        handleChange(event, item.id)
                                    }
                                >
                                    {item?.values?.map((value, valueIndex) => (
                                        <MenuItem
                                            key={valueIndex}
                                            value={value.id || ""}
                                        >
                                            {value.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Fragment>
                ))}
            </Box>
        </>
    );
}

export default TechnicalData;
