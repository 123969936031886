import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";

export default function DetailSkeleton() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile

    return (
        <Box sx={{ mt: 2 }}>
            <Skeleton
                variant="rounded"
                width="100%"
                height={isMobile ? 300 : 500}
            />

            {/* Product Information Skeleton */}
            <div style={{ padding: "20px" }}>
                <Skeleton
                    variant="text"
                    width="50%"
                    height={40}
                    style={{ marginBottom: "10px" }}
                />
                <Skeleton
                    variant="text"
                    width="100%"
                    height={20}
                    style={{ marginBottom: "10px" }}
                />
                <Skeleton
                    variant="text"
                    width="80%"
                    height={20}
                    style={{ marginBottom: "10px" }}
                />
                <Skeleton
                    variant="text"
                    width="60%"
                    height={20}
                    style={{ marginBottom: "10px" }}
                />
                <Skeleton variant="text" width="70%" height={20} />
            </div>
        </Box>
    );
}
