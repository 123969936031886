import api from "@/config/api";
import useAutoCompleteHook from "@/hook/useAutoCompleteHook";
import useProfileHook from "@/hook/useProfileHook";
import { sanitizeImageDomain } from "@/utils";
import { allDays, dayHours, states } from "@/utils/profile";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Avatar,
    Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Skeleton,
    Switch,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import ShowSnackbar from "../shared/modals/Snackbar";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Profile() {
    /**
     * Auto Complete address add feature section
     *
     * In this section we will be implementing the auto complete address feature.
     *
     */

    const {
        autoCompleteLoader,
        handleSearchQuery,
        searchQuery,
        searchedAddress,
    } = useAutoCompleteHook();

    const {
        profile,
        profileImage,
        heroImage,
        setProfile,
        dealerCategories,
        dealerManufacture,
        handleDayChange,
        handleHeroImage,
        handleHolidayChange,
        handleOCTChange,
        handleOPTChange,
        handleProfileImage,
        hoursOperation,
        saveProfileInfo,
        setDealerCategories,
        setDealerManufacture,
        alertInfo,
        setAlertInfo,
        isLoading,
        dealerProfileMutation,
    } = useProfileHook();

    // local function call

    const [loadingAddress, setLoadingAddress] = useState(false);
    const [showAutoComplete, setShowAutoComplete] = useState(false);
    
    async function handleAddressSearch(placeId: StaticRange | string) {
        setLoadingAddress(true);
        try {
            const { data } = await api().post("/dealer/address/info", {
                placeId: placeId,
            });

            const state = states.find(
                (option) => option.abbreviation === data.state
            );

            setProfile((prev) => ({
                ...prev,
                address_one: data.formattedAddress,
                city: data.city,
                stateID: state?.id,
            }));
            setShowAutoComplete(false);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingAddress(false);
        }
    }

    // List of useEffect hook being used

    useEffect(() => {
        const filteredCategories = profile.categories.filter((category) =>
            profile.selectedCategories.includes(category.base_id)
        );

        setDealerCategories(filteredCategories);
    }, [profile.categories, profile.selectedCategories]);

    useEffect(() => {
        const filteredManufactures = profile.manufacturers.filter(
            (manufacture) =>
                profile.selectedManufacturer.includes(manufacture.base_id)
        );
        setDealerManufacture(filteredManufactures);
    }, [profile.manufacturers, profile.selectedManufacturer]);

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading || dealerProfileMutation.isLoading}
            >
                <CircularProgress size={60} color="inherit" />

                <Typography sx={{ ml: 2 }}>Hang on a moment please</Typography>
            </Backdrop>

            <ShowSnackbar
                open={alertInfo.open}
                message={alertInfo.message}
                handleClose={() => setAlertInfo({ open: false, message: "" })}
            />

            <Box
                sx={{
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "white",
                }}
            >
                <h3>Primary Profile Info</h3>

                <FormControl
                    variant="outlined"
                    sx={{ width: { xs: `100%`, sm: `49%` } }}
                >
                    <TextField
                        id="dealer-profile-name"
                        label="Business Name"
                        value={profile.name || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                name: e.target.value,
                            }))
                        }
                        variant="outlined"
                    />
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 0 },
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="outlined"
                >
                    <TextField
                        id="dealer-profile-email"
                        label="Business Email"
                        value={profile.email || ""}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <AlternateEmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled
                    />
                </FormControl>

                <FormControl
                    sx={{
                        mt: 2,
                        width: { xs: `100%`, sm: `49%` },
                    }}
                >
                    {showAutoComplete ? (
                        <Autocomplete
                            id="autocomplete-address"
                            loading={autoCompleteLoader}
                            loadingText="Searching..."
                            isOptionEqualToValue={(option, value) =>
                                option.description === value.description
                            }
                            getOptionLabel={(option) => option.description}
                            options={searchedAddress}
                            onChange={(event, option) => {
                                if (option) {
                                    handleAddressSearch(option.placeId);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Find Location"
                                    variant="outlined"
                                    onChange={handleSearchQuery}
                                    placeholder="Type your location"
                                    value={searchQuery}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <LocationOnRoundedIcon color="action" />
                                        ),
                                        endAdornment: (
                                            <Fragment>
                                                {loadingAddress ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                            </Fragment>
                                        ),
                                    }}
                                />
                            )}
                            noOptionsText={
                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <ErrorOutlineRoundedIcon color="error" />
                                    <Typography
                                        variant="body1"
                                        color="textPrimary"
                                    >
                                        Nothing found
                                    </Typography>
                                </Box>
                            }
                        />
                    ) : (
                        <TextField
                            id="profile-location-unique-id"
                            label="Location"
                            onFocus={() => setShowAutoComplete(true)}
                            value={profile.address_one || ""}
                            variant="outlined"
                        />
                    )}
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: 2,
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                >
                    <TextField
                        id="profile-url-unique-id"
                        label="Profile URL"
                        value={profile.url || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                url: e.target.value,
                            }))
                        }
                        variant="outlined"
                    />
                </FormControl>

                <FormControl
                    sx={{
                        mt: 2,
                        width: { xs: `100%`, sm: `49%` },
                    }}
                    variant="outlined"
                >
                    {loadingAddress ? (
                        <Skeleton variant="rectangular" height={56} />
                    ) : (
                        <TextField
                            id="city-name-unique-id"
                            label="City"
                            value={profile.city || ""}
                            onChange={(e) =>
                                setProfile((prev) => ({
                                    ...prev,
                                    city: e.target.value,
                                }))
                            }
                        />
                    )}
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: 2,
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="outlined"
                >
                    <InputLabel id="state-name-select-label">
                        Select States
                    </InputLabel>

                    {loadingAddress ? (
                        <Skeleton variant="rectangular" height={56} />
                    ) : (
                        <Select
                            labelId="state-name-select-label"
                            id="state-name-select"
                            defaultValue=""
                            value={profile.stateID || ""}
                            onChange={(e) =>
                                setProfile((prev) => ({
                                    ...prev,
                                    stateID: e.target.value,
                                }))
                            }
                            label="Select State"
                        >
                            {states.map((state) => (
                                <MenuItem value={state.id} key={state.id}>
                                    {state.state}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                <FormControl
                    sx={{
                        mt: 2,
                        width: { xs: `100%`, sm: `49%` },
                    }}
                    variant="standard"
                >
                    <TextField
                        id="password-change-unique-id"
                        label="Password"
                        type={"password"}
                        value={profile.password || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                password: e.target.value,
                            }))
                        }
                    />
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: 2,
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="standard"
                >
                    <TextField
                        id="mobile-change-unique-id"
                        label="mobile"
                        value={profile.mobile || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                mobile: e.target.value,
                            }))
                        }
                    />
                </FormControl>

                <FormControl
                    sx={{
                        marginTop: 3,
                        width: { xs: `100%`, sm: `49%` },
                        border: 1,
                        borderColor: "#cbd5e1",
                        py: 1,
                        borderRadius: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: "flex", md: "block", lg: "flex" },
                            alignItems: { sm: "center" },
                            height: "auto",
                        }}
                    >
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                width: { xs: `60%`, lg: `30%` },
                                ml: 2,
                                py: 1,
                                backgroundColor: "#93ca5c",
                                color: "white",
                            }}
                            endIcon={<PhotoCamera />}
                        >
                            Profile Image
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleProfileImage}
                            />
                        </Button>

                        {profile.profile_image && (
                            <Avatar
                                alt={profile.name}
                                src={sanitizeImageDomain(
                                    profile.profile_image.path
                                )}
                                sx={{
                                    height: 50,
                                    width: 50,
                                    ml: 2,
                                    borderRadius: 20,
                                }}
                            />
                        )}
                    </Box>

                    {profileImage && (
                        <Typography
                            component={"p"}
                            sx={{
                                mt: 1,
                                ml: 3,
                            }}
                        >
                            {profileImage["name"]}
                        </Typography>
                    )}
                </FormControl>

                <FormControl
                    sx={{
                        marginTop: 3,
                        width: { xs: `100%`, sm: `49%` },
                        border: 1,
                        ml: { xs: 0, sm: 1, md: 2 },
                        borderColor: "#cbd5e1",
                        py: 1,
                        borderRadius: "10px",
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: "flex", md: "block", lg: "flex" },
                            alignItems: { sm: "center" },
                            height: "auto",
                        }}
                    >
                        <Button
                            variant="contained"
                            component="label"
                            sx={{
                                width: { xs: `60%`, lg: `30%` },
                                ml: 2,
                                py: 1,
                                backgroundColor: "#93ca5c",
                                color: "white",
                            }}
                            endIcon={<PhotoCamera />}
                        >
                            Hero Image
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleHeroImage}
                            />
                        </Button>

                        {profile.hero_image && (
                            <Avatar
                                alt={profile.name}
                                src={sanitizeImageDomain(
                                    profile.hero_image.path
                                )}
                                sx={{
                                    height: 50,
                                    width: 50,
                                    ml: 2,
                                    borderRadius: 20,
                                }}
                            />
                        )}
                    </Box>

                    {heroImage && (
                        <Typography
                            component={"p"}
                            sx={{
                                mt: 1,
                                ml: 3,
                            }}
                        >
                            {heroImage["name"]}
                        </Typography>
                    )}
                </FormControl>
            </Box>

            <Box
                sx={{
                    mt: 3,
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: "white",
                }}
            >
                {/* SECTION 2 OF THE PROFILE PAGE */}
                <h3>Business Information</h3>

                <FormControl
                    variant="outlined"
                    sx={{ width: { xs: `100%`, sm: `49%` } }}
                >
                    <Autocomplete
                        multiple
                        value={dealerCategories}
                        onChange={(event, newValue) => {
                            setDealerCategories(newValue);
                        }}
                        id="categories-id-demo"
                        options={profile.categories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Categories"
                                placeholder="Select Categories"
                            />
                        )}
                    />
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 0 },
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="outlined"
                >
                    <Autocomplete
                        multiple
                        value={dealerManufacture}
                        onChange={(event, newValue) => {
                            setDealerManufacture(newValue);
                        }}
                        id="manufactures-id-demo"
                        options={profile.manufacturers}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Manufactures"
                                placeholder="Select Manufactures"
                            />
                        )}
                    />
                </FormControl>

                <Typography sx={{ mt: 3 }} variant="h5">
                    Hours of Operation
                </Typography>

                {hoursOperation.map((week, index) => (
                    <Box key={week.id}>
                        <FormControl
                            sx={{
                                mt: 2,
                                width: { xs: `100%`, sm: `49%`, md: `25%` },
                            }}
                        >
                            <InputLabel id={`day-select-label-${index}`}>
                                Days
                            </InputLabel>
                            <Select
                                labelId={`day-select-label-${index}`}
                                id="day-select-unique-id"
                                defaultValue=""
                                value={hoursOperation[index].day || ""}
                                label="Days"
                                onChange={(event) =>
                                    handleDayChange(event, index)
                                }
                            >
                                {allDays.map((day, index) => (
                                    <MenuItem value={day} key={index}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                width: { xs: `100%`, sm: `49%`, md: `25%` },
                                ml: { xl: 3 },
                            }}
                        >
                            <InputLabel
                                id={`opening-time-select-label-${index}`}
                            >
                                Opening Time
                            </InputLabel>

                            <Select
                                labelId={`opening-time-select-label-${index}`}
                                id={`hour-select-select-${index}`}
                                defaultValue=""
                                value={hoursOperation[index].opening_time || ""}
                                label="Opening Time"
                                onChange={(event) =>
                                    handleOPTChange(event, index)
                                }
                            >
                                {dayHours.map((hour, index) => (
                                    <MenuItem
                                        value={hour}
                                        key={"day-hours" + index}
                                    >
                                        {hour}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                width: { xs: `100%`, sm: `70%`, md: `25%` },
                                ml: { xl: 3, sm: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id={`closing-time-select-label-${index}`}
                            >
                                Closing Time
                            </InputLabel>

                            <Select
                                labelId={`closing-time-select-label-${index}`}
                                id="closing-time-select-unique-id"
                                defaultValue=""
                                value={hoursOperation[index].closing_time || ""}
                                label="Closing Time"
                                onChange={(event) =>
                                    handleOCTChange(event, index)
                                }
                            >
                                {dayHours.map((hour, index) => (
                                    <MenuItem value={hour} key={index}>
                                        {hour}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2.5,
                                px: 3,
                                py: 0.5,
                                border: 1,
                                borderRadius: 2,
                                borderColor: "#cbd5e1",
                                ml: { xl: 3 },
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            hoursOperation[index].holiday
                                                ? true
                                                : false
                                        }
                                        onChange={(e) =>
                                            handleHolidayChange(e, index)
                                        }
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 13, sm: 16 },
                                        }}
                                    >
                                        Holiday
                                    </Typography>
                                }
                            />
                        </FormControl>
                    </Box>
                ))}
            </Box>

            <Box
                sx={{
                    boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                    p: 2,
                    borderRadius: 2,
                    mt: 3,
                    backgroundColor: "white",
                }}
            >
                <FormControl
                    variant="outlined"
                    sx={{ width: { xs: `100%`, sm: `49%` } }}
                >
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        label="About Dealer"
                        value={profile.description || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                description: e.target.value,
                            }))
                        }
                        rows={5}
                        variant="outlined"
                    />
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: { xs: 2, sm: 0 },
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="outlined"
                >
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        label="Recycling Equipment Maintenance"
                        value={profile.maintenance || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                maintenance: e.target.value,
                            }))
                        }
                        rows={5}
                        variant="outlined"
                    />
                </FormControl>

                <FormControl
                    variant="outlined"
                    sx={{ width: { xs: `100%`, sm: `49%` }, mt: 3 }}
                >
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        label="Baler Replacement Parts"
                        value={profile.parts || ""}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                parts: e.target.value,
                            }))
                        }
                        rows={5}
                        variant="outlined"
                    />
                </FormControl>

                <FormControl
                    sx={{
                        ml: { xs: 0, sm: 2 },
                        mt: 3,
                        width: { xs: `100%`, sm: `48%`, md: `49%` },
                    }}
                    variant="outlined"
                >
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        required
                        value={profile.terms_conditions}
                        onChange={(e) =>
                            setProfile((prev) => ({
                                ...prev,
                                terms_conditions: e.target.value,
                            }))
                        }
                        label="Terms and Conditions"
                        rows={5}
                        variant="outlined"
                    />
                </FormControl>

                <FormGroup sx={{ p: { xs: 1, sm: 4 } }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    profile.email_notification ? true : false
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setProfile((prev) => ({
                                        ...prev,
                                        email_notification: e.target.checked,
                                    }))
                                }
                            />
                        }
                        label="Email Notification"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={
                                    profile.sms_notification ? true : false
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setProfile((prev) => ({
                                        ...prev,
                                        sms_notification: e.target.checked,
                                    }))
                                }
                            />
                        }
                        label="SMS Notification"
                    />
                </FormGroup>

                <LoadingButton
                    onClick={saveProfileInfo}
                    loading={dealerProfileMutation.isLoading}
                    sx={{
                        ml: { xs: 1, sm: 3 },
                        px: 4,
                        backgroundColor: "#93ca5c",
                        py: 1,
                        color: "#f9fafb",
                        ":hover": {
                            backgroundColor: "#166534",
                        },
                    }}
                    variant="contained"
                >
                    Save Profile
                </LoadingButton>
            </Box>
        </>
    );
}

export default Profile;
