import ListSkeleton from "@/components/shared/loaders/ListSkeleton";
import api from "@/config/api";
import { subStrText } from "@/utils/index";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Pagination, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useQuery } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

const fetchQuotations = (page: null | string) =>
    api().get("dealer/quotations", {
        params: {
            page: page,
        },
    });

type QuotationType = {
    data: {
        id: number;
        id_equipment: number;
        id_dealer: number;
        first_name: string;
        quote_date: string;
        last_name: string;
        additional_detail: string;
        equipment: {
            id: number;
            title: string;
            description: string;
            url: string;
        };
        dealer: {
            id: number;
            name: string;
            url: string;
            email: string;
            mobile: string;
        };
    }[];
};

export default function QuotationList() {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [queryParams, setQueryParams] = useSearchParams();

    const { isLoading, isError, error, data } = useQuery({
        queryKey: ["quotations", queryParams.get("page")],
        queryFn: () => fetchQuotations(queryParams.get("page")),
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });

    const quotationList: QuotationType = data?.data.quotations;

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
        queryParams.set("page", value as any);
        setQueryParams(queryParams);
        setCurrentPage(value);
    };

    useEffect(() => {
        setCurrentPage((queryParams.get("page") as any) || 1);
    }, []);

    if (isLoading) {
        return <ListSkeleton total={4} />;
    }

    return (
        <>
            {quotationList.data.map((lead, index) => (
                <Accordion
                    key={lead.id}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 2,
                        overflow: "hidden",
                        mt: 1,
                        boxShadow: `0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                        transition: "box-shadow 0.2s ease-in-out",
                        "&:hover": {
                            boxShadow: `0 4px 8px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22)`,
                        },
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Box
                            sx={{
                                width: `100%`,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography
                                sx={{ fontSize: { xs: 18, sm: 12, md: 16 } }}
                            >
                                {lead.first_name + " " + lead.last_name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: 12, md: 17 },
                                    display: { xs: "none", sm: "block" },
                                }}
                            >
                                {subStrText(
                                    "Requested communication for " +
                                        lead.equipment.title,
                                    60
                                )}
                            </Typography>
                            <Typography
                                sx={{ fontSize: { xs: 16, sm: 12, md: 16 } }}
                            >
                                {lead.quote_date}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: { md: 6, xs: 0 }, py: 2 }}>
                        <Typography variant="h5">Message</Typography>
                        <Typography sx={{ mt: 3 }}>
                            {lead.additional_detail}
                        </Typography>
                        <Divider sx={{ mt: 2, color: "black" }} />
                        <Typography sx={{ mt: 3 }} variant="h5">
                            Source
                        </Typography>
                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                gap: { md: 15, xs: 3, sm: 5 },
                            }}
                        >
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
                                Source
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                                {lead.equipment.title}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                gap: { md: 10.5, xs: 2 },
                            }}
                        >
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
                                Referrer URL
                            </Typography>
                            <Link
                                target={"_blank"}
                                to={lead.equipment.url}
                                style={{ fontSize: 13 }}
                            >
                                {lead.equipment.url}
                            </Link>
                        </Box>

                        <Divider sx={{ mt: 2, color: "black" }} />
                        <Typography sx={{ mt: 3 }} variant="h5">
                            Dealer Info
                        </Typography>

                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 4, md: 15 },
                            }}
                        >
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
                                Name
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                                {lead.dealer?.name || "not available"}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 4, md: 15 },
                            }}
                        >
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
                                Phone
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                                {lead.dealer?.mobile ? (
                                    <PatternFormat
                                        disabled
                                        value={lead.dealer?.mobile}
                                        format="+1 (###) ### - ####"
                                        style={{
                                            border: "none",
                                            fontSize: "1rem",
                                            backgroundColor: "inherit",
                                        }}
                                    />
                                ) : (
                                    "not available"
                                )}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                mt: 3,
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 4.5, md: 15 },
                            }}
                        >
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>
                                Email
                            </Typography>
                            <Typography sx={{ fontSize: 13 }}>
                                <a href="mailto:donnarae@ohogtc.net">
                                    {lead.dealer?.email || "not available"}
                                </a>
                            </Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Stack
                spacing={4}
                sx={{
                    "& .MuiPagination-ul": { justifyContent: "center", mt: 3 },
                }}
            >
                <Pagination
                    count={data?.data.quotations.last_page}
                    page={Number(currentPage)}
                    onChange={handleChange}
                    size="large"
                    color="primary"
                />
            </Stack>
        </>
    );
}
