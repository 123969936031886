import { UsedEquipmentList } from "@/components/app/equipment-lists/UsedEquipmentList";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";
import { Typography } from "@mui/material";

function UsedListings() {
    return (
        <>
            <AppAppBar />
            <Container style={{ mb: { xs: 10, md: 0 } }}>
                <Typography
                    sx={{
                        color: "#93ca5c",
                        fontSize: { md: 35, xs: 25 },
                        fontWeight: 700,
                        mb: { sm: 3, xs: 1 },
                        textAlign: "center",
                    }}
                >
                    Current Equipment
                </Typography>

                <UsedEquipmentList />
            </Container>
        </>
    );
}

export default UsedListings;
