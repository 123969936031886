import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const skeletonHeight = 200;

export default function CardSkeleton({ total }: { total: number }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ px: 1 }}
            >
                {Array.from(Array(total)).map((item, index) => (
                    <Grid item xs={12} sm={4} md={3} key={index}>
                        <Box sx={{ width: "100%", m: 1, my: 4 }}>
                            <Skeleton
                                variant="rounded"
                                width={"100%"}
                                height={skeletonHeight}
                            />

                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
