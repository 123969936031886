import FilterButton from "@/components/shared/FilterButton";
import CardSkeleton from "@/components/shared/loaders/CardSkeleton";
import api from "@/config/api";
import { mutateCatalogFilters } from "@/features/catalogSlice";
import useAutoCompleteHook from "@/hook/useAutoCompleteHook";
import { CatalogStateType } from "@/store/types/store-types";
import { ConfirmHandlerTypes } from "@/types/filters-equipment";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import CatalogList from "./CatalogList";
import FilteredList from "./FilteredList";

const fetchCatalogs = ({ pageParam = 1 }) =>
    api().get("dealer/equipment/catalog", {
        params: {
            page: pageParam,
        },
    });

export default function DealerCatalog() {
    const dispatch = useDispatch();
    const state = useSelector((state: CatalogStateType) => state.catalogSlice);

    /**
     * Auto Complete address add feature section
     *
     * In this section we will be implementing the auto complete address feature.
     *
     */

    const {
        autoCompleteLoader,
        handleSearchQuery,
        searchQuery,
        searchedAddress,
        setShowManualAddress,
        showManualAddress,
    } = useAutoCompleteHook();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [searchAddress, setSearchAddress] = useState<string>("");

    const {
        data,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetching,
        isFetchingNextPage,
        refetch,
    } = useInfiniteQuery(["all-catalog"], fetchCatalogs, {
        getNextPageParam: (_lastPage, pages) => {
            if (pages.length < 4) {
                return pages.length + 1;
            } else {
                return undefined;
            }
        },
        onSuccess(data) {
            dispatch(
                mutateCatalogFilters({
                    filters: data?.pages[0].data?.catalog_filters.filters,
                })
            );
        },
        refetchOnWindowFocus: false,
    });

    const [filteredData, setFilteredData] = useState([]);
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    async function handleConfirmFilter({
        equipmentType,
        typeCategory,
        manufacture,
        condition,
        minPriceRange,
        maxPriceRange,
        listingId,
    }: ConfirmHandlerTypes) {
        setIsFilterLoading(true);

        try {
            const { data } = await api().get("dealer/equipment/catalog", {
                params: {
                    filter: true,
                    type: equipmentType,
                    category: typeCategory,
                    condition: condition,
                    manufacturer: manufacture,
                    listingId: listingId || null,
                    price_range:
                        minPriceRange || maxPriceRange
                            ? minPriceRange + "-" + maxPriceRange
                            : null,
                },
            });
            setIsFilterSelected(true);
            setFilteredData(data.equipment.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFilterLoading(false);
            setAnchorEl(null);
        }
    }

    async function handleLocationFilter(location: string) {
        console.log("location ", location);

        setIsFilterLoading(true);

        try {
            const { data } = await api().get("dealer/equipment/catalog", {
                params: {
                    filter: true,
                    location: location,
                },
            });
            setIsFilterSelected(true);
            setFilteredData(data.equipment.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsFilterLoading(false);
            setAnchorEl(null);
        }
    }

    function handleClearFilter() {
        setIsFilterSelected(false);
        setAnchorEl(null);
        refetch({
            refetchPage: (page, index) => index === 0,
        });
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 3,
                }}
            >
                {!isLoading ? (
                    <FilterButton
                        anchorEl={anchorEl}
                        handleFilterOpen={(payload) => setAnchorEl(payload)}
                        filtersInfo={state.catalogFilters as any}
                        onConfirmFilter={handleConfirmFilter}
                        onClearFilter={handleClearFilter}
                        isSearching={isFilterLoading}
                        isClearing={isFetching}
                    />
                ) : (
                    <LoadingButton
                        id="loading-filter-button"
                        loading={isFetching}
                        variant="contained"
                        color="primary"
                        sx={{ px: 6, py: 1.5 }}
                    >
                        Filter
                    </LoadingButton>
                )}

                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isFilterLoading}
                >
                    <CircularProgress size={70} color="inherit" />

                    <Typography
                        className="brand-text-color"
                        sx={{
                            ml: 2,
                            fontSize: 20,
                            fontWeight: 700,
                            textTransform: "uppercase",
                        }}
                    >
                        searching your desired catalog
                    </Typography>
                </Backdrop>

                <Autocomplete
                    id="asynchronous-demo"
                    loading={autoCompleteLoader}
                    loadingText="Searching..."
                    sx={{
                        width: `20%`,
                        boxShadow: `0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)`,
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.description === value.description
                    }
                    getOptionLabel={(option) => option.description}
                    options={searchedAddress}
                    onChange={(event, option) => {
                        if (option) {
                            handleLocationFilter(option.description);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Find Location"
                            variant="outlined"
                            onChange={handleSearchQuery}
                            placeholder="Type your location"
                            value={searchQuery}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <LocationOnRoundedIcon color="action" />
                                ),
                                endAdornment: (
                                    <Fragment>
                                        {autoCompleteLoader ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : (
                                            <IconButton
                                                onClick={handleClearFilter}
                                                edge="end"
                                                aria-label="clear"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                    </Fragment>
                                ),
                            }}
                        />
                    )}
                    noOptionsText={
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <ErrorOutlineRoundedIcon color="error" />
                            <Typography variant="body1" color="textPrimary">
                                Nothing found
                            </Typography>
                        </Box>
                    }
                />
            </Box>

            <Grid container spacing={2}>
                {isLoading ? (
                    <CardSkeleton total={8} />
                ) : isFilterSelected ? (
                    <FilteredList data={filteredData} />
                ) : (
                    <>
                        <CatalogList data={data as any} />
                        <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            direction={"column"}
                        >
                            <Box
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {isFetching && isFetchingNextPage ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    <Button
                                        disabled={!hasNextPage}
                                        onClick={() => fetchNextPage()}
                                        variant="contained"
                                        sx={{ px: 4 }}
                                        endIcon={<ArrowCircleDownIcon />}
                                    >
                                        load more
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}
