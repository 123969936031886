import { Alert, AlertTitle, Box, Divider, Typography } from "@mui/material";

// If using TypeScript, add the following snippet to your file as well.
declare global {
    namespace JSX {
        interface IntrinsicElements {
            "stripe-pricing-table": React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >;
        }
    }
}

function Billing() {
    return (
        <>
            <Alert variant="filled" severity="info">
                <AlertTitle>Free</AlertTitle>
                Free Membership is Active — <strong>check it out!</strong>
            </Alert>

            <Box
                sx={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "6px",
                    p: 2,
                    mb: 5,
                    mt: 2,
                    backgroundColor: "white",
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: 18, sm: 22 },
                        fontWeight: { xs: 600, sm: 700 },
                    }}
                >
                    Choose Your Subscription Plan
                </Typography>
                <Divider sx={{ mt: 2 }} />
                <stripe-pricing-table
                    pricing-table-id={
                        import.meta.env.VITE_STRIPE_PRICING_TABLE_ID
                    }
                    publishable-key={
                        import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
                    }
                ></stripe-pricing-table>
            </Box>

           
        </>
    );
}

export default Billing;
