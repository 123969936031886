import Billing from "@/components/account/Billing";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";

function BillingView() {
    return (
        <>
            <AppAppBar />
            <Container>
                <Billing />
            </Container>
        </>
    );
}

export default BillingView;
