import { fetchUsedEquipment } from "@/apis/equipment";
import { mutateInformationData } from "@/features/equipmentSlice";
import {
    AddEquipmentType,
    InformationFormValues,
} from "@/store/types/store-types";
import { FormHelperText, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

// Define the type for the component's props
type InformationProps = {
    onChangeState: (formValues: InformationFormValues) => void;
};

// Define the type for the imperative handle
type InformationImperativeHandle = {
    onSaveAction: () => void;
};

const Information = forwardRef<InformationImperativeHandle, InformationProps>(
    (props, ref) => {
        const { onChangeState } = props;

        const dispatch = useDispatch();

        const state = useSelector(
            (state: AddEquipmentType) => state.equipmentAdd.information
        );

        const {
            register,
            handleSubmit,
            control,
            getValues,
            setValue,
            trigger,
            clearErrors,
            formState: { errors, isValid },
        } = useForm<InformationFormValues>({
            mode: "onTouched",
            defaultValues: state,
        });

        const { data, isLoading } = useQuery(
            "used-equipment",
            fetchUsedEquipment,
            {
                cacheTime: 3699999, // equal to 1 hour
                staleTime: 30000,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            }
        );

        function processFormData() {
            const formValues = getValues();
            dispatch(mutateInformationData(formValues));
            onChangeState(formValues);
        }

        // This is a Forward Ref
        useImperativeHandle(ref, () => ({
            onSaveAction() {
                trigger().then((isValid) => {
                    if (isValid) {
                        processFormData();
                    }
                });
            },
        }));

        useEffect(() => {
            Object.entries(state).forEach(([key, value]) => {
                setValue(key as any, value);
            });
        }, [state, setValue]);

        return (
            <>
                <Box
                    component={"div"}
                    sx={{
                        boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                        p: 2,
                        borderRadius: 2,
                        backgroundColor: "white",
                    }}
                >
                    <Typography
                        component={"h3"}
                        variant="inherit"
                        sx={{ fontWeight: 700, fontSize: 20 }}
                        mb={2}
                    >
                        Primary Details
                    </Typography>

                    <form>
                        <FormControl
                            variant="outlined"
                            sx={{ width: { xs: `100%`, sm: `49%` } }}
                        >
                            <InputLabel id="equipment-type-select-label">
                                Equipment Type
                            </InputLabel>

                            <Controller
                                name="id_equipment_type"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Equipment type is required",
                                }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Select
                                        id="equipment-type-selected"
                                        labelId="equipment-type-select-label"
                                        label="Equipment Type"
                                        error={!!errors.id_equipment_type}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        onClick={() =>
                                            clearErrors("id_equipment_type")
                                        }
                                        value={value || ""}
                                    >
                                        {data?.data.equipmentType.map(
                                            (item: any, index: number) => (
                                                <MenuItem
                                                    value={item.base_id}
                                                    key={item.base_id}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            />

                            {errors.id_equipment_type && (
                                <FormHelperText color="red">
                                    {errors.id_equipment_type?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                            }}
                            variant="outlined"
                        >
                            <InputLabel id="equipment-category-select-label">
                                Equipment Category
                            </InputLabel>

                            <Controller
                                name="id_category"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: "Category field is require",
                                }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Select
                                        labelId="equipment-category-select-label"
                                        id="equipment-category-select"
                                        label="Equipment Category"
                                        error={!!errors.id_category}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        onClick={() =>
                                            clearErrors("id_category")
                                        }
                                        value={value || ""}
                                    >
                                        {data?.data.equipmentCategory.map(
                                            (item: any, index: number) => (
                                                <MenuItem
                                                    value={item.base_id}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            />

                            {errors.id_category && (
                                <FormHelperText color="red">
                                    {errors.id_category?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {/* When shrink is true the label is stay at the top of the input field */}
                        <FormControl
                            sx={{
                                marginTop: 2,
                                width: { xs: `100%`, sm: `49%` },
                            }}
                        >
                            <TextField
                                id="id-equipment-title"
                                label="Title"
                                {...register("title", {
                                    required: "This field is required",
                                    maxLength: {
                                        value: 70,
                                        message: "Title cannot be that long",
                                    },
                                })}
                                placeholder="Enter equipment title"
                                variant="outlined"
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={() => clearErrors("title")}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                                ml: { xs: 0, sm: 2 },
                            }}
                        >
                            <TextField
                                id="equipment-model-number"
                                label="Model"
                                type="text"
                                {...register("model_number", {
                                    required: "Model number is required",
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message:
                                            "Model number has to be a number",
                                    },
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="Enter model number"
                                onChange={() => clearErrors("model_number")}
                                variant="outlined"
                                error={!!errors.model_number}
                                helperText={errors.model_number?.message}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                marginTop: 2,
                                width: { xs: `100%`, sm: `49%` },
                            }}
                            variant="outlined"
                        >
                            <TextField
                                id="id-equipment-price-field"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("price", {
                                    required: "Price field is required",
                                    pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message: "Price field is incorrect",
                                    },
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="Customer price"
                                onChange={() => clearErrors("price")}
                                label="Enter customer price"
                                error={!!errors.price}
                                helperText={errors.price?.message}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                ml: { xs: 0, sm: 2 },
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                            }}
                            variant="standard"
                        >
                            <TextField
                                id="id-equipment-dealer-price-field"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                                type="number"
                                {...register("msrp", {
                                    required: "Dealer price is required",
                                    pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message: "Price field is incorrect",
                                    },
                                })}
                                placeholder="Dealer price"
                                label="Enter dealer Price"
                                onChange={() => clearErrors("msrp")}
                                error={!!errors.msrp}
                                helperText={errors.msrp?.message}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                width: { xs: `100%`, sm: `49%` },
                            }}
                            variant="outlined"
                        >
                            <InputLabel id="equipment-condition-select-label">
                                Equipment Condition
                            </InputLabel>

                            <Controller
                                name="id_equipment_condition"
                                control={control}
                                rules={{
                                    required: "Equipment condition is required",
                                }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Select
                                        labelId="equipment-condition-select-label"
                                        id="id-equipment-condition"
                                        label="Equipment Condition"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        onClick={() =>
                                            clearErrors(
                                                "id_equipment_condition"
                                            )
                                        }
                                        value={value || ""}
                                        error={!!errors.id_equipment_condition}
                                    >
                                        {data?.data.equipmentCondition.map(
                                            (item: any, index: number) => (
                                                <MenuItem
                                                    value={item.id}
                                                    key={item.id}
                                                >
                                                    {item.value}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            />

                            {errors.id_equipment_condition && (
                                <FormHelperText color="red">
                                    {errors.id_equipment_condition?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                ml: { xs: 0, sm: 2 },
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                            }}
                            variant="outlined"
                        >
                            <InputLabel id="equipment-manufacture-select-label">
                                Manufacture
                            </InputLabel>

                            <Controller
                                name="id_manufacturer"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required:
                                        "Equipment manufacture is required",
                                }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Select
                                        labelId="equipment-manufacture-select-label"
                                        id="id-equipment-manufacture"
                                        label="Manufacture"
                                        error={!!errors.id_manufacturer}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        onClick={() =>
                                            clearErrors("id_manufacturer")
                                        }
                                        value={value || ""}
                                    >
                                        {data?.data.equipmentManufacture.data.map(
                                            (item: any, index: number) => (
                                                <MenuItem
                                                    value={item.base_id}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                )}
                            />

                            {errors.id_manufacturer && (
                                <FormHelperText color="red">
                                    {errors.id_manufacturer?.message}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,

                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                            }}
                        >
                            <TextField
                                id="location-input-box"
                                label="Location"
                                type="text"
                                {...register("address", {
                                    required: "Address is required",
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={() => clearErrors("address")}
                                variant="outlined"
                                placeholder="Enter equipment location"
                                error={!!errors.address}
                                helperText={errors.address?.message}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                mt: 2,
                                ml: { xs: 0, sm: 2 },
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                            }}
                        >
                            <TextField
                                id="id-zipcode-input"
                                placeholder="Enter zip code"
                                variant="outlined"
                                label="Zip Code"
                                {...register("zipcode", {
                                    required: "Zip code is required",
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message: "zip code must be a number",
                                    },
                                })}
                                onChange={() => clearErrors("zipcode")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.zipcode}
                                helperText={errors.zipcode?.message}
                            />
                        </FormControl>

                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: 20,
                                mb: 2,
                                mt: 2,
                            }}
                        >
                            Shipping Details
                        </Typography>

                        <FormControl
                            sx={{ width: { xs: `100%`, sm: `49%` } }}
                            variant="standard"
                        >
                            <TextField
                                id="id-equipment-weight"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            lbs
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="Enter equipment weight"
                                {...register("weight", {
                                    required: "Please specify the weight field",
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message: "Weight has to be a number",
                                    },
                                })}
                                onChange={() => clearErrors("weight")}
                                label="Equipment Weight"
                                error={!!errors.weight}
                                helperText={errors.weight?.message}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                width: { xs: `100%`, sm: `48%`, md: `49%` },
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                            }}
                            variant="standard"
                        >
                            <TextField
                                id="id-equipment-height"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            feet
                                        </InputAdornment>
                                    ),
                                }}
                                {...register("feet", {
                                    required: "Height field is required",
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message: "Height has to be a number",
                                    },
                                })}
                                onChange={() => clearErrors("feet")}
                                placeholder="Enter equipment height"
                                label="Equipment Height"
                                error={!!errors.feet}
                                helperText={errors.feet?.message}
                            />
                        </FormControl>

                        <FormControl sx={{ mt: 2, mb: 3, width: `99%` }}>
                            <TextField
                                id="id-equipment-trucks-total"
                                {...register("trucks", {
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message:
                                            "Total number of trucks should be a number",
                                    },
                                })}
                                label="How many trucks does this shipment needs?"
                                placeholder="Enter total number of truck required"
                                variant="outlined"
                                error={!!errors.trucks}
                                helperText={errors.trucks?.message}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <Typography
                            sx={{ fontWeight: 700, fontSize: 20 }}
                            mb={2}
                        >
                            Other Details
                        </Typography>

                        <FormControl variant="standard" sx={{ width: `99%` }}>
                            <TextField
                                id="id-equipment-load-time"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            weeks
                                        </InputAdornment>
                                    ),
                                }}
                                label="Load Time?"
                                placeholder="Enter load time"
                                {...register("lead_time", {
                                    pattern: {
                                        value: /^\d*\.?\d+$/,
                                        message: "load time should be a number",
                                    },
                                })}
                                error={!!errors.lead_time}
                                helperText={errors.lead_time?.message}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl
                            variant="standard"
                            sx={{ mt: 2, width: `99%` }}
                        >
                            <TextField
                                multiline
                                rows={7}
                                {...register("description")}
                                label="Write your description"
                                placeholder="Write description"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>

                        <FormControl sx={{ mt: 2, width: `99%` }}>
                            <label htmlFor="terms-conditions">
                                Terms and Conditions
                            </label>
                            <TextField
                                id="id-terms-conditions"
                                multiline
                                required
                                {...register("terms", {
                                    required:
                                        "Please add your terms and condition",
                                })}
                                placeholder="Enter your terms and condition"
                                variant="outlined"
                                error={!!errors.terms}
                                onChange={() => clearErrors("terms")}
                                helperText={errors.terms?.message}
                            />
                        </FormControl>

                        <FormControlLabel
                            control={<Checkbox {...register("used")} />}
                            label="Save this terms & conditions for future use"
                        />
                    </form>
                </Box>
            </>
        );
    }
);

export default Information;
