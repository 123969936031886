import { deleteEquipmentImage, makeImageCover } from "@/apis/equipment";
import ShowSnackbar from "@/components/shared/modals/Snackbar";
import {
    mutateEmbedVideos,
    mutateMediaImages,
} from "@/features/equipmentSlice";
import { AddEquipmentType } from "@/store/types/store-types";
import { SavedImagesType, SavedVideosType } from "@/types/create-equipment";
import { sanitizeImageDomain } from "@/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

interface FilePreview {
    file: File;
    previewUrl: string;
}

type MediaProps = {
    savedImages?: SavedImagesType[];
    savedVideos?: SavedVideosType[];
    coverImageId?: number | null;
    onStatusChanged: () => void
};

export default function Media({
    savedImages,
    savedVideos,
    coverImageId,
    onStatusChanged
}: MediaProps) {

    const dispatch = useDispatch();

    const state = useSelector((state: AddEquipmentType) => state.equipmentAdd);

    const { id } = useParams();

    const [searchParams] = useSearchParams();

    const [filePreviews, setFilePreviews] = useState<FilePreview[]>([]);

    const handleDrop = (acceptedFiles: File[]) => {
        const previews = acceptedFiles.map((file) => {
            const previewUrl = URL.createObjectURL(file);
            return { file, previewUrl };
        });
        setFilePreviews([...previews, ...filePreviews]);
        dispatch(mutateMediaImages([...previews, ...filePreviews]));
    };

    const removeFile = (index: number) => {
        const newPreviews = [...filePreviews];
        newPreviews.splice(index, 1);
        setFilePreviews(newPreviews);
        dispatch(mutateMediaImages(newPreviews));
    };

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        message: "",
    });

    const [deleteImageId, setDeleteImageId] = useState(null);

    const deleteImageMutation = useMutation({
        mutationFn: (params: any) => {
            setDeleteImageId(params.imageId);
            return deleteEquipmentImage(params);
        },
        onSuccess: ({ data }) => {
            onStatusChanged()
            setAlertInfo({
                open: true,
                message: data.message,
            });
        },
    });

    const [makeCoverId, setMakeCoverId] = useState(null);
    const makeImageCoverMutation = useMutation({
        mutationFn: (payload: any) => {
            setMakeCoverId(payload.imageId);
            return makeImageCover(payload);
        },
        onSuccess: ({ data }) => {
            onStatusChanged()
            setAlertInfo({
                open: true,
                message: data.message,
            });
        },
    });

    const [openVideoInput, setOpenVideoInput] = useState(false);

    const [embedCode, setEmbedCode] = useState("");

    function handleInsertVideo(code: string) {
        dispatch(
            mutateEmbedVideos({
                embedCode: code,
            })
        );
        setOpenVideoInput(false);
        setEmbedCode("");
    }

    // this should go last
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        accept: {
            "image/jpeg": [],
            "image/png": [],
        },
    });

    return (
        <>
            <ShowSnackbar
                open={alertInfo.open}
                message={alertInfo.message}
                handleClose={() => setAlertInfo({ open: false, message: "" })}
            />

            <div {...getRootProps()} style={{ minHeight: 100 }}>
                <input {...getInputProps()} />
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        border: 1,
                        borderRadius: 2,
                        borderStyle: "dotted",
                        textAlign: "center",
                        borderColor: "#cbd5e1",
                        py: 5,
                        pl: 2,
                        cursor: "pointer",
                        backgroundColor: "#f3f4f6",
                        color: "#475569",
                    }}
                >
                    Drag and drop your files here OR
                    <Button variant="contained" color="primary" sx={{ ml: 3 }}>
                        Select Files
                    </Button>
                </Typography>
            </div>

            {/* {filePreviews.length >= 1 && (
                <Box textAlign="center">
                    <LoadingButton
                        loading={equipmentMediaMutation.isLoading}
                        endIcon={<CloudUploadIcon />}
                        variant="contained"
                        onClick={handleMediaUpload}
                    >
                        Upload File
                    </LoadingButton>
                </Box>
            )} */}

            <Box
                sx={{
                    mt: 1,
                    border: 1,
                    borderStyle: "dashed",
                    borderRadius: 2,
                    textAlign: "center",
                    borderColor: "#cbd5e1",
                    py: 5,
                    cursor: "pointer",
                    backgroundColor: "#f3f4f6",
                    color: "#475569",
                    minHeight: `400px`,
                }}
            >
                <Grid container spacing={2}>
                    {state.mediaImages?.map((preview, index) => (
                        <Grid item xs={6} sm={4} md={3} key={index}>
                            <>
                                <img
                                    src={preview.previewUrl}
                                    style={{
                                        width: "90%",
                                        height: `350px`,
                                        objectFit: "cover",
                                        borderRadius: 4,
                                    }}
                                    alt={preview.file.name}
                                />
                                <Typography variant="subtitle1">
                                    {preview.file.name}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => removeFile(index)}
                                >
                                    Remove
                                </Button>
                            </>
                        </Grid>
                    ))}

                    {savedImages &&
                        savedImages?.map((image, index) => (
                            <Grid
                                item
                                xs={6}
                                sm={4}
                                md={3}
                                key={index}
                                sx={{ position: "relative" }}
                            >
                                <img
                                    src={sanitizeImageDomain(image.path)}
                                    style={{
                                        width: "90%",
                                        height: `350px`,
                                        objectFit: "cover",
                                        borderRadius: 4,
                                    }}
                                    alt={image.name}
                                />

                                <Box
                                    sx={{
                                        mt: 1,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        px: 3,
                                    }}
                                >
                                    <LoadingButton
                                        onClick={() =>
                                            makeImageCoverMutation.mutate({
                                                equipmentId:
                                                    searchParams.get("id") ||
                                                    id,
                                                imageId: image.id,
                                            })
                                        }
                                        loading={
                                            makeImageCoverMutation.isLoading &&
                                            makeCoverId === image.id
                                        }
                                        variant="outlined"
                                    >
                                        Make Cover
                                    </LoadingButton>
                                    <LoadingButton
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        loading={
                                            deleteImageMutation.isLoading &&
                                            deleteImageId === image.id
                                        }
                                        onClick={() =>
                                            deleteImageMutation.mutate({
                                                equipmentId:
                                                    searchParams.get("id") ||
                                                    id,
                                                imageId: image.id,
                                            })
                                        }
                                    >
                                        Delete
                                    </LoadingButton>
                                </Box>

                                {image.id === coverImageId && (
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: 26,
                                            right: 34,
                                            color: "#fff",
                                        }}
                                        variant="contained"
                                    >
                                        Cover
                                    </Button>
                                )}
                            </Grid>
                        ))}
                </Grid>
            </Box>

            <Button
                sx={{ px: 6, mt: 2 }}
                onClick={() => setOpenVideoInput(true)}
                variant="contained"
            >
                Videos
            </Button>

            <Grid container spacing={2}>
                {state.embedVideos.map((source, index) => (
                    <Grid item xs={6} md={4} key={index}>
                        <Box sx={{ mt: 3 }}>
                            <iframe
                                width="580"
                                height="300"
                                src={source}
                                title="YouTube Embed Video"
                                frameBorder={0}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                                style={{ borderRadius: 8 }}
                            ></iframe>
                        </Box>
                    </Grid>
                ))}

                {savedVideos &&
                    savedVideos?.map((source, index) => (
                        <Grid item xs={6} md={4} key={index}>
                            <Box sx={{ mt: 3 }}>
                                <iframe
                                    width="580"
                                    height="300"
                                    src={source.path}
                                    title="YouTube Embed Video"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ borderRadius: 8 }}
                                ></iframe>
                            </Box>
                        </Grid>
                    ))}
            </Grid>

            <Dialog
                open={openVideoInput}
                onClose={() => setOpenVideoInput(false)}
                fullWidth
            >
                <DialogTitle>Add YouTube Video</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Paste your YouTube video embed code or the share link.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        multiline
                        value={embedCode}
                        onChange={(e) => setEmbedCode(e.target.value)}
                        margin="dense"
                        rows={8}
                        id="name"
                        label="Embed Code"
                        type="email"
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenVideoInput(false)}>
                        Cancel
                    </Button>
                    <Button onClick={() => handleInsertVideo(embedCode)}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
