import CatalogDetail from "@/components/app/equipment-lists/catalog/CatalogDetail";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";
import { Box } from "@mui/system";

function CatalogDetailView() {
    return (
        <>
            <AppAppBar />
            <Container>
                <CatalogDetail />
            </Container>
        </>
    );
}

export default CatalogDetailView;
