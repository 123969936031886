import {
    Categories,
    ConfirmHandlerTypes,
    FiltersType,
    Manufacture,
} from "@/types/filters-equipment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { ChangeEvent, useState } from "react";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={2}
        sx={{ mt: 0.5, ml: { sm: 5, xs: -1 } }}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        minWidth: 320,
        paddingTop: 6,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],

        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

type FilterButtonProps = {
    filtersInfo: FiltersType;
    onConfirmFilter: (filters: ConfirmHandlerTypes) => void;
    onClearFilter: () => void;
    isSearching: boolean;
    isClearing: boolean;
    anchorEl: any;
    handleFilterOpen: (arg: any) => void;
};

export default function FilterButton({
    filtersInfo,
    onConfirmFilter,
    onClearFilter,
    isSearching,
    isClearing,
    anchorEl,
    handleFilterOpen,
}: FilterButtonProps) {
    const openFilter = Boolean(anchorEl);

    /** responsible for type and type category data */
    const [typeInfo, setTypeInfo] = useState<{
        typeId: number | null;
        categories: Categories[];
    }>({
        typeId: null,
        categories: [],
    });

    const [equipmentTypeExpanded, setEquipmentTypeExpanded] = useState(true);
    const [selectedTypeName, setSelectedTypeName] = useState<
        string | undefined
    >("");
    const handleTypeChange = (event: any) => {
        const category = filtersInfo.type.find(
            (item) => item.id == event.target.value
        );

        setSelectedTypeName(category?.name);

        setTypeInfo((prev) => ({
            ...prev,
            categories: category?.categories_trimmed_for_type || [],
            typeId: event.target.value,
        }));
        setEquipmentTypeExpanded(false);
        setEquipmentCategoryExpanded(true);
    };

    /** this piece of code is responsible for handling category data */

    const [equipmentCategoryExpanded, setEquipmentCategoryExpanded] =
        useState(false);
    const [selectedCategoryName, setSelectedCategoryName] = useState<
        string | undefined
    >("");
    const [typeCategoryInfo, setTypeCategoryInfo] = useState<{
        typeCategory: number | null;
        manufactures: Manufacture[];
    }>({ typeCategory: null, manufactures: [] });

    const handleCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
        const manufactures = typeInfo.categories.find(
            (item) => String(item.id) == event.target.value
        );

        setSelectedCategoryName(manufactures?.name);

        setTypeCategoryInfo((prev) => ({
            ...prev,
            manufactures: manufactures?.manufacturers_trimmed || [],
            typeCategory: Number(event.target.value),
        }));

        setEquipmentCategoryExpanded(false);
        setManufactureExpanded(true);
    };

    /** Responsible for handling manufacture changes */
    const [manufactureId, setManufactureId] = useState<string | null>(null);
    const [manufactureExpanded, setManufactureExpanded] = useState(false);
    const [selectedManufacture, setSelectedManufacture] = useState<
        string | undefined
    >("");
    const handleManufactureChange = (event: ChangeEvent<HTMLInputElement>) => {
        setManufactureId(event.target.value);

        const manufacture = typeCategoryInfo.manufactures.find(
            (item) => String(item.id) == event.target.value
        );
        setSelectedManufacture(manufacture?.name);
        setManufactureExpanded(false);
        setConditionExpanded(true);
    };

    /** Responsible for handling equipment condition changes */
    const [conditionId, setConditionId] = useState<string | null>(null);
    const [conditionExpanded, setConditionExpanded] = useState(false);
    const [selectedCondition, setSelectedCondition] = useState<
        string | undefined
    >("");
    const handleConditionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setConditionId(event.target.value);

        const condition = filtersInfo.condition.find(
            (item) => String(item.id) == event.target.value
        );
        setSelectedCondition(condition?.name);
        setConditionExpanded(false);
        setPriceRangeExpanded(true);
    };

    /** Responsible for others filters states  */
    const [priceRangeExpanded, setPriceRangeExpanded] = useState(false);
    const [listingIdExpanded, setListingIdExpanded] = useState(false);
    const [minPriceRange, setMinPriceRange] = useState<string | null>(null);
    const [maxPriceRange, setMaxPriceRange] = useState<string | null>(null);
    const [listingId, setListingId] = useState<string>("");

    function handleFilterClick() {
        onConfirmFilter({
            equipmentType: typeInfo.typeId,
            typeCategory: typeCategoryInfo.typeCategory,
            manufacture: manufactureId,
            condition: conditionId,
            minPriceRange: minPriceRange,
            maxPriceRange: maxPriceRange,
            listingId: listingId,
        });
    }

    function handleClearFilter() {
        setTypeInfo({ typeId: null, categories: [] });
        setSelectedCategoryName("");
        setSelectedTypeName("");
        setSelectedCondition("");
        setSelectedManufacture("");

        setTypeCategoryInfo({ typeCategory: null, manufactures: [] });
        setManufactureId(null);
        setConditionId(null);
        setMinPriceRange(null);
        setMaxPriceRange(null);

        onClearFilter();
    }

    return (
        <>
            <Button
                id="catalog-filter-button"
                aria-controls={openFilter ? "catalog-filter-button" : undefined}
                aria-expanded={openFilter ? "true" : undefined}
                variant="contained"
                onClick={(event) => handleFilterOpen(event.currentTarget)}
                startIcon={<FilterFramesIcon />}
                sx={{
                    px: { xs: 2, sm: 6 },
                    py: { sm: 1.5, xs: 1 },
                    width: { xs: "100%", sm: "auto" },
                    // mt: 2,
                }}
            >
                Filter Catalog
            </Button>
            <StyledMenu
                id="equipment-filter-menu"
                anchorEl={anchorEl}
                open={openFilter}
                onClose={() => handleFilterOpen(null)}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        justifyItems: "center",
                    }}
                >
                    <LoadingButton
                        sx={{ px: 3, mb: 3 }}
                        loading={isClearing}
                        size="small"
                        variant="outlined"
                        onClick={handleClearFilter}
                    >
                        Clear
                    </LoadingButton>
                    <Typography sx={{ fontWeight: 600, p: 1 }}>
                        Filters
                    </Typography>
                    <LoadingButton
                        onClick={handleFilterClick}
                        loading={isSearching}
                        sx={{ px: 3, mb: 3 }}
                        size="small"
                        variant="contained"
                        disableElevation
                    >
                        Done
                    </LoadingButton>
                </Box>

                <Accordion
                    expanded={equipmentTypeExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() =>
                            setEquipmentTypeExpanded((prev) => !prev)
                        }
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-one-content"
                        id="accordion-one-header"
                    >
                        <Box>
                            <Typography>Equipment Type</Typography>
                            <Typography
                                sx={{
                                    mt: 1,
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontStyle: "oblique",
                                }}
                            >
                                {selectedTypeName}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -3 }}>
                        <RadioGroup
                            aria-labelledby="equipment-type-radio"
                            name="equipment-type-radio-name"
                            value={typeInfo.typeId || ""}
                            onChange={handleTypeChange}
                        >
                            {filtersInfo.type.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    value={item.id}
                                    control={<Radio />}
                                    label={item.name}
                                />
                            ))}
                        </RadioGroup>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={equipmentCategoryExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() =>
                            setEquipmentCategoryExpanded((prev) => !prev)
                        }
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-two-content"
                        id="accordion-two-header"
                    >
                        <Box>
                            <Typography>Equipment Categories</Typography>
                            <Typography
                                sx={{
                                    pt: 0.5,
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontStyle: "oblique",
                                }}
                            >
                                {selectedCategoryName}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -3 }}>
                        {typeInfo.categories.length > 0 ? (
                            <RadioGroup
                                aria-labelledby="type-category-radio-button"
                                value={typeCategoryInfo.typeCategory || ""}
                                onChange={handleCategoryChange}
                                name="equipment-type-category-radio"
                            >
                                {typeInfo.categories.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={item.id}
                                        control={<Radio />}
                                        label={item.name}
                                    />
                                ))}
                            </RadioGroup>
                        ) : (
                            <Alert
                                sx={{
                                    fontSize: 16,
                                }}
                                variant="outlined"
                                severity="warning"
                            >
                                Please select the equipment type
                            </Alert>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={manufactureExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() => setManufactureExpanded((prev) => !prev)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-three-content"
                        id="accordion-three-header"
                    >
                        <Box>
                            <Typography>Manufacturers</Typography>
                            <Typography
                                sx={{
                                    mt: 1,
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontStyle: "oblique",
                                }}
                            >
                                {selectedManufacture}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -3 }}>
                        {typeCategoryInfo.manufactures.length > 0 ? (
                            <RadioGroup
                                value={manufactureId || ""}
                                onChange={handleManufactureChange}
                                aria-labelledby="type-category-radio-button"
                                name="manufacture-radio-button"
                            >
                                {typeCategoryInfo.manufactures.map(
                                    (item, index) => (
                                        <FormControlLabel
                                            key={index}
                                            value={item.id}
                                            control={<Radio />}
                                            label={item.name}
                                        />
                                    )
                                )}
                            </RadioGroup>
                        ) : (
                            <Alert
                                sx={{
                                    fontSize: 16,
                                }}
                                variant="outlined"
                                severity="warning"
                            >
                                Please select the equipment category
                            </Alert>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={conditionExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() => setConditionExpanded((prev) => !prev)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-four-content"
                        id="accordion-four-header"
                    >
                        <Box>
                            <Typography>Equipment Condition</Typography>
                            <Typography
                                sx={{
                                    mt: 1,
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontStyle: "oblique",
                                }}
                            >
                                {selectedCondition}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -3 }}>
                        <RadioGroup
                            aria-labelledby="equipment-condition-radio-button"
                            name="equipment-condition"
                            value={conditionId || ""}
                            onChange={handleConditionChange}
                        >
                            {filtersInfo.condition.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.id}
                                    control={<Radio />}
                                    label={item.name}
                                />
                            ))}
                        </RadioGroup>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={priceRangeExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() => setPriceRangeExpanded((prev) => !prev)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-five-content"
                        id="accordion-five-header"
                    >
                        <Box>
                            <Typography>Price</Typography>
                            {minPriceRange && (
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: 600,
                                        fontStyle: "oblique",
                                    }}
                                >
                                    {minPriceRange}{" "}
                                    {maxPriceRange && "-" + " " + maxPriceRange}
                                </Typography>
                            )}
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails sx={{ mt: -2 }}>
                        <FormControl sx={{ width: `45%` }}>
                            <InputLabel
                                sx={{ mt: -1 }}
                                id="minimum-price-range-input"
                            >
                                Min
                            </InputLabel>
                            <Select
                                labelId="minimum-price-range-input"
                                id="min-price-range"
                                size="small"
                                value={minPriceRange || ""}
                                label="Min"
                                onChange={(e) =>
                                    setMinPriceRange(e.target.value)
                                }
                            >
                                {filtersInfo.price_range.map((price, index) => (
                                    <MenuItem
                                        sx={{
                                            p: 1,
                                            border: "1px solid",
                                            borderColor: "divider",
                                            backgroundColor: "background.paper",
                                        }}
                                        key={index}
                                        value={price}
                                    >
                                        {price}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="priceDivider"> - </div>
                        <FormControl sx={{ width: `45%` }}>
                            <InputLabel
                                sx={{ mt: -1 }}
                                id="maximum-price-range-input"
                            >
                                Max
                            </InputLabel>
                            <Select
                                labelId="maximum-price-range-input"
                                id="max-price-range"
                                value={maxPriceRange || ""}
                                size="small"
                                label="Max"
                                onChange={(e) => {
                                    setMaxPriceRange(e.target.value);
                                    setPriceRangeExpanded(false);
                                    setListingIdExpanded(true);
                                }}
                            >
                                {filtersInfo.price_range.map((price, index) => (
                                    <MenuItem
                                        key={index}
                                        value={price}
                                        sx={{
                                            p: 1,
                                            border: "1px solid",
                                            borderColor: "divider",
                                            backgroundColor: "background.paper",
                                        }}
                                    >
                                        {price}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={listingIdExpanded}
                    sx={{ "&.Mui-expanded": { margin: "0px !important" } }}
                >
                    <AccordionSummary
                        onClick={() => setListingIdExpanded((prev) => !prev)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="accordion-six-content"
                        id="accordion-six-header"
                    >
                        <Box>
                            <Typography>Listing ID</Typography>
                            <Typography
                                sx={{
                                    mt: 1,
                                    fontSize: 12,
                                    fontWeight: 600,
                                    fontStyle: "oblique",
                                }}
                            >
                                {listingId}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ mt: -2 }}>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            value={listingId}
                            onChange={(event) =>
                                setListingId(event.target.value)
                            }
                            label="Listing ID"
                            variant="outlined"
                        />
                    </AccordionDetails>
                </Accordion>
            </StyledMenu>
        </>
    );
}
