export const states = [
    { id: 1, state: "Alabama", abbreviation: "AL" },
    { id: 2, state: "Alaska", abbreviation: "AK" },
    { id: 3, state: "Arizona", abbreviation: "AZ" },
    { id: 4, state: "Arkansas", abbreviation: "AR" },
    { id: 5, state: "California", abbreviation: "CA" },
    { id: 6, state: "Colorado", abbreviation: "CO" },
    { id: 7, state: "Connecticut", abbreviation: "CT" },
    { id: 8, state: "Delaware", abbreviation: "DE" },
    { id: 9, state: "Florida", abbreviation: "FL" },
    { id: 10, state: "Georgia", abbreviation: "GA" },
    { id: 11, state: "Hawaii", abbreviation: "HI" },
    { id: 12, state: "Idaho", abbreviation: "ID" },
    { id: 13, state: "Illinois", abbreviation: "IL" },
    { id: 14, state: "Indiana", abbreviation: "IN" },
    { id: 15, state: "Iowa", abbreviation: "IA" },
    { id: 16, state: "Kansas", abbreviation: "KS" },
    { id: 17, state: "Kentucky", abbreviation: "KY" },
    { id: 18, state: "Louisiana", abbreviation: "LA" },
    { id: 19, state: "Maine", abbreviation: "ME" },
    { id: 20, state: "Maryland", abbreviation: "MD" },
    { id: 21, state: "Massachusetts", abbreviation: "MA" },
    { id: 22, state: "Michigan", abbreviation: "MI" },
    { id: 23, state: "Minnesota", abbreviation: "MN" },
    { id: 24, state: "Mississippi", abbreviation: "MS" },
    { id: 25, state: "Missouri", abbreviation: "MO" },
    { id: 26, state: "Montana", abbreviation: "MT" },
    { id: 27, state: "Nebraska", abbreviation: "NE" },
    { id: 28, state: "Nevada", abbreviation: "NV" },
    { id: 29, state: "New Hampshire", abbreviation: "NH" },
    { id: 30, state: "New Jersey", abbreviation: "NJ" },
    { id: 31, state: "New Mexico", abbreviation: "NM" },
    { id: 32, state: "New York", abbreviation: "NY" },
    { id: 33, state: "North Carolina", abbreviation: "NC" },
    { id: 34, state: "North Dakota", abbreviation: "ND" },
    { id: 35, state: "Ohio", abbreviation: "OH" },
    { id: 36, state: "Oklahoma", abbreviation: "OK" },
    { id: 37, state: "Oregon", abbreviation: "OR" },
    { id: 38, state: "Pennsylvania", abbreviation: "PA" },
    { id: 39, state: "Rhode Island", abbreviation: "RI" },
    { id: 40, state: "South Carolina", abbreviation: "SC" },
    { id: 41, state: "South Dakota", abbreviation: "SD" },
    { id: 42, state: "Tennessee", abbreviation: "TN" },
    { id: 43, state: "Texas", abbreviation: "TX" },
    { id: 44, state: "Utah", abbreviation: "UT" },
    { id: 45, state: "Vermont", abbreviation: "VT" },
    { id: 46, state: "Virginia", abbreviation: "VA" },
    { id: 47, state: "Washington", abbreviation: "WA" },
    { id: 48, state: "West Virginia", abbreviation: "WV" },
    { id: 49, state: "Wisconsin", abbreviation: "WI" },
    { id: 50, state: "Wyoming", abbreviation: "WY" },
];

export const allDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

export const dayHours = [
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
];

export function stringConversion(str: string) {
    return str === "true" ? true : false;
}

export function nullConversion(data: any) {
    return data === "null" ? null : data;
}
