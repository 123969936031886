import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Represents the React App component
 * @component
 *
 * Whenever user tries to remove the 'equipment/list'
 * and hit enter from the base URL, this function will
 * redirect the user to the equipment/list page.
 */
function App() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/equipment");
    }, [navigate]);
    return null;
}

export default App;
