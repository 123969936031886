import Login from "@/components/account/LoginView";
import { Box } from "@mui/material";

function LoginView() {
    return (
        <div style={{ background: "white", minHeight: "100vh" }}>
            <Box sx={{ pt: 16 }}>
                <Login />
            </Box>
        </div>
    );
}

export default LoginView;
