import {
    saveEquipmentDrafts,
    toggleSoldEquipment,
    updateEquipment,
} from "@/apis/equipment";
import { InformationState } from "@/store/types/store-types";
import { ToggleType } from "@/types/option-type";
import queryString from "query-string";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export default function useEquipmentHook(refetch?: () => void) {
    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { id } = useParams();

    // Local States and Functions
    const [alertInfo, setAlertInfo] = useState({
        open: false,
        message: "",
    });

    const [equipmentId, setEquipmentId] = useState<number | null>(null);
    const [soldStatus, setSoldStatus] = useState<"success" | "error" | "">("");
    const [activeStep, setActiveStep] = useState(0);

    // create equipment draft
    const equipmentDraftMutation = useMutation(
        (payload: InformationState) => {
            const formData: any = new FormData();

            formData.append("draft", 1);

            for (let [key, value] of Object.entries(payload)) {
                formData.append(key, value);
            }

            return saveEquipmentDrafts(formData);
        },
        {
            onSuccess: ({ data }) => {
                setAlertInfo({
                    open: true,
                    message: data.message,
                });

                const url = `?id=${data.equipmentId}&${queryString.stringify({
                    added: true,
                })}`;

                navigate(url);

                setActiveStep(1);
            },
            onSettled: () => {
                setTimeout(() => {
                    setAlertInfo({
                        open: false,
                        message: "",
                    });
                }, 3000);
            },
        }
    );

    /** Update Equipment Information */

    const equipmentUpdateMutation = useMutation(
        (data: InformationState) => {
            const formData: any = new FormData();

            for (let [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            const payload = {
                equipmentId: id,
                formData: formData,
            };

            return updateEquipment(payload);
        },
        {
            onSuccess: ({ data }) => {
                setAlertInfo({
                    open: true,
                    message: data.message,
                });

                refetch && refetch();

                setActiveStep(1);
            },
            onError: () => {
                setAlertInfo({
                    open: true,
                    message: "Something went wrong, try again",
                });
            },
        }
    );

    // sold and unsold equipment call
    const equipmentSoldMutation = useMutation({
        mutationFn: (payload: ToggleType) => {
            setEquipmentId(payload.equipmentId);
            return toggleSoldEquipment(payload);
        },
        onSuccess: ({ data }) => {
            if (data.success) {
                setSoldStatus("success");
                queryClient.invalidateQueries();
            } else {
                setSoldStatus("error");
            }
            setAlertInfo({
                open: true,
                message: data.message,
            });
        },
        onError: () => {
            setAlertInfo({
                open: true,
                message: "Something went wrong, try again",
            });
        },
    });

    return {
        activeStep,
        setActiveStep,
        alertInfo,
        equipmentId,
        soldStatus,
        equipmentDraftMutation,
        equipmentUpdateMutation,
        equipmentSoldMutation,
        setAlertInfo,
    };
}
