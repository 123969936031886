import { capitalize } from "@/utils";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Fragment, useState } from "react";

type SnackProps = {
    open: boolean;
    message: string;
    handleClose: () => void;
};

export default function ShowSnackbar({
    open,
    message,
    handleClose,
}: SnackProps) {
    const [state, setState] = useState<SnackbarOrigin>({
        vertical: "top",
        horizontal: "center",
    });
    const { vertical, horizontal } = state;

    const action = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={7000}
                action={action}
                onClose={handleClose}
                message={capitalize(message)}
                key={vertical + horizontal}
            />
        </Box>
    );
}
