import { fetchEquipmentById } from "@/apis/equipment";
import ShowSnackbar from "@/components/shared/modals/Snackbar";
import {
    mutateInformationData,
    setSavedOptions,
} from "@/features/equipmentSlice";
import useEquipmentHook from "@/hook/useEquipmentHook";
import useEquipmentOptionHook from "@/hook/useEquipmentOptionHook";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Backdrop,
    CircularProgress,
    StepButton,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { Fragment, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AdditionalInformation from "./AdditionalInformation";
import Information from "./Information";
import Media from "./Media";
import Resources from "./Resources";
import TechnicalData from "./TechnicalData";

const steps = [
    "Information",
    "Technical Data",
    "Addition Information",
    "Resources",
    "Media",
];

function EditFormStepper() {
    const childRef = useRef<any>();

    const dispatch = useDispatch();

    const { id } = useParams();

    const { data, isLoading, refetch } = useQuery(
        ["edit-single-equipment", id],
        () => fetchEquipmentById(id as any),
        {
            enabled: !!id,
            refetchOnWindowFocus: false,
            onSuccess: ({ data }) => {
                dispatch(mutateInformationData(data.data));
                if (data.data.savedValues !== null) {
                    dispatch(setSavedOptions(data.data.savedValues));
                } else {
                    dispatch(setSavedOptions([]));
                }
            },
        }
    );

    /** custom hook for handing equipment options */
    const { equipmentOptionMutation, equipmentMediaMutation } =
        useEquipmentOptionHook(refetch);

    /** custom hook to handle equipment draft and toggle equipment sold */
    const {
        equipmentUpdateMutation,
        activeStep,
        setActiveStep,
        alertInfo,
        setAlertInfo,
    } = useEquipmentHook(refetch);

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={
                    equipmentOptionMutation.isLoading ||
                    equipmentUpdateMutation.isLoading ||
                    isLoading
                }
            >
                <CircularProgress size={60} color="inherit" />

                <Typography sx={{ ml: 2 }}>Hang on a moment please</Typography>
            </Backdrop>

            <ShowSnackbar
                open={alertInfo.open}
                message={alertInfo.message}
                handleClose={() => setAlertInfo({ open: false, message: "" })}
            />

            <Box sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                color="inherit"
                                onClick={() => setActiveStep(index)}
                            >
                                <Typography
                                    sx={{
                                        display: { xs: "none", md: "block" },
                                    }}
                                >
                                    {label}
                                </Typography>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                <Fragment>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Information
                                ref={childRef}
                                onChangeState={(data) =>
                                    equipmentUpdateMutation.mutate(data)
                                }
                            />
                        )}
                        {activeStep === 1 && (
                            <TechnicalData
                                data={data?.data.data.technicalData}
                            />
                        )}
                        {activeStep === 2 && (
                            <AdditionalInformation
                                data={data?.data.data.additionalInformation}
                            />
                        )}
                        {activeStep === 3 && (
                            <Resources
                                brochureFile={data?.data.data.brochureFile}
                                specSheet={data?.data.data.specSheet}
                            />
                        )}
                        {activeStep > 3 && (
                            <Media
                                savedImages={data?.data.data.mediaImages}
                                savedVideos={data?.data.data.mediaVideos}
                                coverImageId={data?.data.data.coverImageId}
                                onStatusChanged={() => refetch()}
                            />
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            pt: 2,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="outlined"
                            disabled={activeStep === 0}
                            onClick={() =>
                                setActiveStep(
                                    (prevActiveStep) => prevActiveStep - 1
                                )
                            }
                            sx={{ px: 4, py: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />

                        <Box sx={{ mb: 4 }}>
                            {activeStep === steps.length - 1 ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "end",
                                        mt: 4,
                                    }}
                                >
                                    <LoadingButton
                                        sx={{ px: 5, py: 1 }}
                                        loading={
                                            equipmentOptionMutation.isLoading
                                        }
                                        variant="contained"
                                        onClick={() =>
                                            Promise.all([
                                                equipmentOptionMutation.mutate({
                                                    equipmentId: id,
                                                    action: "edit",
                                                }),
                                                equipmentMediaMutation.mutate(
                                                    id
                                                ),
                                            ])
                                        }
                                    >
                                        Update
                                    </LoadingButton>
                                </Box>
                            ) : (
                                <>
                                    {activeStep === 0 ? (
                                        <>
                                            <LoadingButton
                                                sx={{ px: 5, py: 1 }}
                                                loading={
                                                    equipmentUpdateMutation.isLoading
                                                }
                                                variant="contained"
                                                onClick={() =>
                                                    childRef?.current.onSaveAction()
                                                }
                                            >
                                                Update Equipment
                                            </LoadingButton>
                                        </>
                                    ) : (
                                        <Button
                                            sx={{ px: 5, py: 1 }}
                                            variant="contained"
                                            onClick={() =>
                                                setActiveStep(
                                                    (prevActiveStep) =>
                                                        prevActiveStep + 1
                                                )
                                            }
                                        >
                                            Next
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </Fragment>
            </Box>
        </>
    );
}

export default EditFormStepper;
