import { Box, SxProps } from "@mui/system";
import { ReactNode } from "react";

interface ContainerProps {
    children: ReactNode;
    style?: SxProps;
}

function Container({ children, style }: ContainerProps) {
    return (
        <>
            <Box
                sx={{
                    mt: { sm: 14, xs: 8 },
                    px: { lg: 6, xs: 0, sm: 3 },
                    mb: { xs: 8, md: 0 },
                    ...style,
                }}
            >
                {children}
            </Box>
        </>
    );
}

export default Container;
