import { EquipmentType } from "@/types/catalog-type";
import { formattedPrice, subStrText, transformImageUrl } from "@/utils";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

type FilteredListProps = {
    data: EquipmentType[];
};

function FilteredList({ data }: FilteredListProps) {
    if (data.length <= 0) {
        return (
            <>
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    direction={"column"}
                >
                    <Grid item xs={3}>
                        <Card elevation={0} sx={{ width: "100%", mt: 4 }}>
                            <CardMedia
                                height="350"
                                width="350"
                                sx={{
                                    objectFit: "contain",
                                    backgroundColor: "#F5EFEF",
                                }}
                                component="img"
                                image="/imgs/no-data.svg"
                                alt={"No data found"}
                            />
                        </Card>
                        <Typography
                            sx={{
                                mt: 4,
                                fontSize: 26,
                                textAlign: "center",
                                pl: 4,
                                fontWeight: 600,
                            }}
                        >
                            No Catalog Found
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <>
            {data.map((catalog, index) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={"catalog-filter-" + index}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
                            transition: "box-shadow 0.2s ease-in-out",
                            cursor: "pointer",
                            "&:hover": {
                                boxShadow: `0 4px 28px rgba(0,0,0,0.25), 0 3px 5px rgba(0,0,0,0.22)`,
                            },
                        }}
                    >
                        <CardMedia
                            sx={{ objectFit: "cover" }}
                            component="img"
                            height="200"
                            image={transformImageUrl(catalog.coverImage)}
                            alt={"Recycling Baler Image of " + catalog.title}
                        />

                        <CardContent>
                            <Typography
                                sx={{ fontSize: 12, fontWeight: 600 }}
                                gutterBottom
                            >
                                {catalog.condition?.value}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: `1.875rem`,
                                    lineHeight: `2.25rem`,
                                    color: "#93ca5c",
                                }}
                                gutterBottom
                            >
                                {catalog.msrp
                                    ? formattedPrice(catalog.msrp)
                                    : "Call for pricing"}
                            </Typography>

                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                color="textSecondary"
                                sx={{
                                    fontWeight: 600,
                                }}
                            >
                                {subStrText(catalog.title, 45)}
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mb: 2,
                                    mt: 1,
                                }}
                            >
                                <Typography sx={{ fontSize: 12 }}>
                                    {catalog.map}
                                </Typography>

                                <Typography sx={{ fontSize: 11 }}>
                                    {Math.ceil(catalog.distance)} Mi. Away
                                </Typography>
                            </Box>

                            <Link to={`/catalog/${catalog.id}`}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#93ca5c",
                                        border: "none",
                                        color: "white",
                                        ":hover": {
                                            backgroundColor: "#93ca5c",
                                            border: "none",
                                        },
                                    }}
                                    fullWidth
                                >
                                    View Equipment
                                </Button>
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </>
    );
}

export default FilteredList;
