import { TOKEN_KEY } from "@/hook/useAuthUserHook";
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state object
const initialState = {
    dealerInfo: {
        name: "",
        email: "",
        avatar: "",
    },
    token: "",
};

export const dealerSlice = createSlice({
    name: "DealerSlice",
    initialState,
    reducers: {
        mutateDealerInfo: (state, { payload }) => {
            state.dealerInfo.name = payload.name;
            state.dealerInfo.email = payload.email;
            state.dealerInfo.avatar = payload?.profile_image?.path;
            const dealerInfo = {
                name: payload.name,
                email: payload.email,
                avatar: payload?.profile_image?.path,
            };
            const dealerInfoJSON = JSON.stringify(dealerInfo);
            localStorage.setItem("dealerInfo", dealerInfoJSON);
        },

        setDealerToken: (state, { payload }) => {
            state.token = payload;
            localStorage.setItem(TOKEN_KEY, payload);
        },

        unsetDealerToken: (state) => {
            state.token = "";
            localStorage.removeItem(TOKEN_KEY);
        },

        resetEquipmentState: () => initialState,
    },
});

// Action creators are generated for each case reducer function
export const {
    resetEquipmentState,
    mutateDealerInfo,
    setDealerToken,
    unsetDealerToken,
} = dealerSlice.actions;

export default dealerSlice.reducer;
