import { createSlice } from "@reduxjs/toolkit";

// Define the initial state object
const initialState = {
	catalogFilters: {},
};

export const catalogSlice = createSlice({
	name: "CatalogSlice",
	initialState,
	reducers: {
		mutateCatalogFilters: (state, { payload }) => {
			state.catalogFilters = payload.filters;
		},

		resetEquipmentState: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { resetEquipmentState, mutateCatalogFilters } = catalogSlice.actions;

export default catalogSlice.reducer;
