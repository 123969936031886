import EditFormStepper from "@/components/app/equipment-forms/EditFormStepper";
import Container from "@/components/layout/Container";
import AppAppBar from "@/components/layout/NewAppBar";

function EditEquipment() {
    return (
        <>
            <AppAppBar />
            <Container style={{ mt: 16 }}>
                <EditFormStepper />
            </Container>
        </>
    );
}

export default EditEquipment;
