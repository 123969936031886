import CardSkeleton from "@/components/shared/loaders/CardSkeleton";
import InformationModal from "@/components/shared/modals/InformationModal";
import ShowSnackbar from "@/components/shared/modals/Snackbar";
import api from "@/config/api";
import useEquipmentHook from "@/hook/useEquipmentHook";
import useResponsiveHook from "@/hook/useResponsiveHook";
import { SoldEquipmentType } from "@/types/equipment-list";
import { formattedPrice, STORAGE_DOMAIN, subStrText } from "@/utils";
import AddLinkRoundedIcon from "@mui/icons-material/AddLinkRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EditIcon from "@mui/icons-material/Edit";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const fetchSoldEquipments = () =>
    api().get("dealer/equipment/sold-list", {
        params: {
            page: 1,
        },
    });

export default function SoldEquipmentList() {
    const { sm } = useResponsiveHook();

    // Custom Equipment Hook
    const {
        equipmentSoldMutation,
        alertInfo,
        equipmentId,
        soldStatus,
        setAlertInfo,
    } = useEquipmentHook();

    // fetch sold equipment data
    const { data, isLoading } = useQuery(
        "sold-equipments",
        fetchSoldEquipments,
        {
            refetchOnWindowFocus: false,
        }
    );

    // Destructure the equipment data
    const equipments: SoldEquipmentType[] = data?.data.data;

    // Local states and functions
    const [showInformationModal, setShowInformationModal] = useState(false);

    const [singleEquipment, setSingleEquipment] = useState({});

    return (
        <>
            <ShowSnackbar
                open={alertInfo.open}
                message={alertInfo.message}
                handleClose={() =>
                    setAlertInfo((prev) => ({ ...prev, open: false }))
                }
            />

            <InformationModal
                open={showInformationModal}
                handleClose={() => setShowInformationModal(false)}
                equipment={singleEquipment as SoldEquipmentType}
            />

            <Grid container spacing={2}>
                {isLoading ? (
                    <CardSkeleton total={8} />
                ) : (
                    <>
                        {equipments?.map((equip, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                xl={3}
                                key={equip.id}
                            >
                                <Card sx={{ maxWidth: 500 }}>
                                    <CardHeader
                                        action={
                                            <Link
                                                target={"_blank"}
                                                to={equip.fullURL}
                                                title="View Equipment"
                                            >
                                                <AddLinkRoundedIcon />
                                            </Link>
                                        }
                                        title={
                                            <Typography
                                                sx={{
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {subStrText(equip.title, 30)}
                                            </Typography>
                                        }
                                        subheader={equip.formatted_date}
                                    />

                                    {equip.coverImageId ? (
                                        <CardMedia
                                            sx={{ objectFit: "cover" }}
                                            component="img"
                                            height="194"
                                            image={
                                                STORAGE_DOMAIN +
                                                equip.coverImage
                                            }
                                            alt={
                                                "Recycling Baler Image of " +
                                                equip.title
                                            }
                                        />
                                    ) : (
                                        <CardMedia
                                            sx={{ objectFit: "contain" }}
                                            component="img"
                                            height="194"
                                            image={equip.coverImage}
                                            alt={
                                                "Recycling Baler Image of " +
                                                equip.title
                                            }
                                        />
                                    )}

                                    <CardContent>
                                        <Box
                                            sx={{
                                                overflow: "hidden",
                                                mt: 1,
                                                textAlign: "center",
                                                border: 1,
                                                borderColor: "#d4d4d8",
                                                borderRadius: 2,
                                                pl: { xs: 0, md: 1 },
                                            }}
                                        >
                                            <Typography
                                                component={"div"}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: { xs: 1, md: 2 },
                                                }}
                                            >
                                                <p>Customer Price</p>
                                                <p
                                                    style={{
                                                        fontWeight: 700,
                                                        color: "green",
                                                    }}
                                                >
                                                    {formattedPrice(
                                                        equip.customerPrice
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: { xs: 1, md: 2 },
                                                }}
                                            >
                                                <p>Dealer Price</p>
                                                <p
                                                    style={{
                                                        fontWeight: 700,
                                                        color: "green",
                                                    }}
                                                >
                                                    {formattedPrice(
                                                        equip.dealerPrice
                                                    )}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: { xs: 1, md: 2 },
                                                }}
                                            >
                                                <p>Type</p>
                                                <p>
                                                    {equip?.equipmentType?.name}
                                                </p>
                                            </Typography>

                                            <Divider />

                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent:
                                                        "space-between",
                                                    px: { xs: 1, md: 2 },
                                                }}
                                            >
                                                <p>Status</p>
                                                {equip.status === 1 ? (
                                                    <Button
                                                        startIcon={
                                                            <CheckBoxIcon />
                                                        }
                                                        size="small"
                                                        disableElevation
                                                        sx={{
                                                            backgroundColor:
                                                                "#93ca5c",

                                                            color: "#f9fafb",
                                                            ":hover": {
                                                                backgroundColor:
                                                                    "#166534",
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Approved
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        startIcon={
                                                            <PendingIcon />
                                                        }
                                                        size="small"
                                                        disableElevation
                                                        sx={{
                                                            backgroundColor:
                                                                "#f59e0b",
                                                            color: "#f9fafb",
                                                            ":hover": {
                                                                backgroundColor:
                                                                    "#d97706",
                                                            },
                                                        }}
                                                        variant="contained"
                                                    >
                                                        Pending
                                                    </Button>
                                                )}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <CardActions
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Button
                                            size={sm ? "small" : "medium"}
                                            startIcon={<EditIcon />}
                                        >
                                            Edit
                                        </Button>
                                        {equipmentSoldMutation.isLoading &&
                                        equipmentId === equip.id ? (
                                            <Button
                                                variant="outlined"
                                                size="medium"
                                            >
                                                <CircularProgress size={22} />
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    equipmentSoldMutation.mutate(
                                                        {
                                                            equipmentId:
                                                                equip.id,
                                                            status: 0,
                                                        }
                                                    )
                                                }
                                                variant="outlined"
                                                size={sm ? "small" : "medium"}
                                                startIcon={<SellIcon />}
                                            >
                                                {soldStatus === "success" &&
                                                equipmentId === equip.id ? (
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 600,
                                                            color: "green",
                                                        }}
                                                    >
                                                        Unsold
                                                    </Typography>
                                                ) : soldStatus === "error" &&
                                                  equipmentId === equip.id ? (
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 600,
                                                            color: "red",
                                                        }}
                                                    >
                                                        Unsold Failed
                                                    </Typography>
                                                ) : (
                                                    "Mark Unsold"
                                                )}
                                            </Button>
                                        )}
                                        <Button
                                            size={sm ? "small" : "medium"}
                                            onClick={() => {
                                                setSingleEquipment(equip);
                                                setShowInformationModal(true);
                                            }}
                                            startIcon={
                                                <VisibilityRoundedIcon />
                                            }
                                        >
                                            View More
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        </>
    );
}
