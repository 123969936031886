import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface RequiredAuthProps {
    children: ReactNode;
}

function RequiredAuth({ children }: RequiredAuthProps): JSX.Element {
    const isAuthenticated = localStorage.getItem("dealer-auth-token");

    if (!isAuthenticated) {
        return <Navigate to={"/login"} replace={true} />;
    }

    return <>{children}</>;
}

export default RequiredAuth;
